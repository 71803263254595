import React from "react";

import { useHistory } from 'react-router-dom';

import FirstMessage from "../components/firstMessage";
// import Destaque from "../components/destaque";
// import Video from "../components/video";
// import Impacto from "../components/impacto";
import Instrucoes from "../components/instrucoes";
import Categories from "../components/categories";
import AdList from "../components/adlist";
import { SearchField } from "../pages/search";

function Start(props) {

	const history = useHistory();
	React.useEffect(() => {
		document.title = "Auati - Seu trabalho, sua moeda"
	});

	function openCategory(category) {
		history.push(`/c/${category.slug}`);
		return true;
	}

	return (
		<main className="start">
			<FirstMessage />
			<Instrucoes />
			<section className="explore">
				<SearchField />
				<Categories action={openCategory} />
			</section>
			<section className="adcover">
                <AdList />
			</section>
		</main>
	);
}

export default Start;
