import React from 'react';
import GlobalContext from '../globalContext.js';


class ShareButton extends React.Component {

    constructor(props) {
        super(props);
        this.openShareDialog = this.openShareDialog.bind(this);
        this.copyLink = this.copyLink.bind(this);
        this.shareWhatsapp = this.shareWhatsapp.bind(this);
        this.shareInstagram = this.shareInstagram.bind(this);
        this.shareFacebook = this.shareFacebook.bind(this);
        this.shareLinkedin = this.shareLinkedin.bind(this);
        this.WhatsappButton = this.WhatsappButton.bind(this);
        this.InstagramButton = this.InstagramButton.bind(this);
        this.FacebookButton = this.FacebookButton.bind(this);
        this.LinkedinButton = this.LinkedinButton.bind(this);
        this.link = `https://auati.com.br/${this.props.ad.person.user.username}/${this.props.ad.slug}`;
    }

    static contextType = GlobalContext;

    copyLink() {
        const link = this.link;
        navigator.clipboard.writeText(link);
        this.context.setMsg('Link copiado.', 'success');
    }

    shareWhatsapp() {
        const ad = this.props.ad;
        const offer = ad.offer_tags.map(o => o.name).join(", ")
        const text = "Olha o que encontrei no Auati:\n\n*" + this.link + "*\n\nQue tal trocar o que você faz com " + ad.person.fullname + "?\n\nServiços oferecidos: *" + offer + "*";
        const link = encodeURI("https://api.whatsapp.com/send/?text=" + text);
        window.open(link, '_blank', "noopener");
    }
    
    shareInstagram() {      
        const link = encodeURI("https://www.instagram.com/?url=" + this.link);
        window.open(link, '_blank', "noopener");
    }
    
    shareFacebook() {
        const link = "https://www.facebook.com/sharer/sharer.php?u=" + this.link + "&cancel_url=https://www.facebook.com/dialog/close_window/&connect=0#_=_&display=popup&locale=pt_BR";
        window.open(link, '_blank', "noopener");
    }
    
    shareLinkedin() {
        const link = encodeURI("https://www.linkedin.com/sharing/share-offsite/?url=" + this.link);
        window.open(link, '_blank', "noopener");
        
    }   

    WhatsappButton() {
        return (
        <li className='whatsapp'>
            <button className='social whatsapp' onClick={this.shareWhatsapp}>
                <small>Whatsapp</small>
            </button>
        </li>
        );
    }

    FacebookButton() {
        return (
        <li className='facebook'>
            <button className='social facebook' onClick={this.shareFacebook}>
                <small>Facebook</small>
            </button>
        </li>
        );
    }
    InstagramButton() {
        return false && (
        <li className='instagram'>
            <button className='social instagram' onClick={this.shareInstagram}>
                <small>Instagram</small>
            </button>
        </li>
        );
    }
    LinkedinButton() {
        return false && (
        <li className='linkedin'>
            <button className='social linkedin' onClick={this.shareLinkedin}>
                <small>Linkedin</small>
            </button>
        </li>
        );
    }

    openShareDialog() {
        const link = this.link;
        this.context.openDialog({
            name: 'share',
            title: 'Compartilhe',
            modal: false,
            content: <div className='sharing'>
                <ul>
                    <this.WhatsappButton />
                    <this.InstagramButton />
                    <this.FacebookButton />
                    <this.LinkedinButton />
                </ul>
                <div className='copylink'>
                    <span>{link}</span>
                    <button type='submit' onClick={this.copyLink}>Copiar link</button>
                </div>
            </div>
        });
    }
    
    render() {
        return (
            <button className="share" onClick={this.openShareDialog}></button>
        )
    }
}

export default ShareButton;