import React from "react";
import Poster from './poster.js';
import Actions from './actions.js';
import { Link } from 'react-router-dom';

class Card extends React.Component {

    Tag({tag, link}) {
        return tag ? (
            <span className='tag'>
                { link ? (<Link to={`/tag/${tag.slug}`}>{tag.name}</Link>) : (tag.name) }
            </span>
        ) : "";
    }

    StatusMessage({status}) {
        const texts = {
            draft: "🚧 Rascunho",
            pending: "⏳ Em análise",
            published: "✅ Publicado",
            suspend: "🔒 Suspenso"
        };
        return <div className="status"><span>{texts[status]}</span></div>;
    }

    render() {

        const ad = this.props.ad;
        
        const classname = ad.plan || 'default';
        const want = ad.want_tags.map((tag, i) => (
            <React.Fragment key={tag.id}>{i > 0 && ", "}<this.Tag tag={tag} link={false} key={tag.id} /></React.Fragment>
        ));
        const offer = ad.offer_tags.map((tag, i) => (
            <React.Fragment key={tag.id}>{i > 0 && ", "}<this.Tag tag={tag} link={false} key={tag.id} /></React.Fragment>
        ));

        return (
            <li className={`card ${classname} ${ad.status}`}>
                <Link to={`/${ad.person.user.username}/${ad.slug}`}>
                    <Poster ad={ad} />
                    <header>
                        <h3>
                            <span>{ad.title}</span>
                        </h3>
                        <p className="description">
                            <b>Ofereço:</b> 
                            <span>{offer} {ad.offer}</span>
                        </p>
                    </header>
                    <p className="accept">
                        {(want.length > 0 || ad.want) && ( <b>Aceito:</b> )}
                        <span>{want} {ad.want}</span>
                    </p>
                </Link>
                {(ad.status === 'published' || ad.status === 'draft') && <Actions ad={ad} />}
                {(ad.status !== 'published' && ad.status !== 'draft') && <this.StatusMessage status={ad.status} />}
            </li>
        )
    }
}

export default Card;