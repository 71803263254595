import React from 'react';
import GlobalContext from '../globalContext.js';
import Stars from '../components/stars.js';

class Ratings extends React.Component {

    constructor(props) {
        super(props);
        this.link = `https://auati.com.br/${this.props.ad.person.nickname}/${this.props.ad.slug}`;
    }

	static contextType = GlobalContext;

    render() {
        const ad = this.props.ad;
        const r = ad.ratings.length;
        return (
        <>
            { r > 0 ? (
                <div className='rating'>
                    <h4>
                        Este anúncio possui {r} {r > 1 ? "avaliações": "avaliação"}
                    </h4>
                    <Stars ad={ad} />
                    <output>5.0</output>
                </div>
            ) : (
                <p>Este anúncio ainda não possui avaliações suficientes.</p>
            )}
            <a href={this.link}>Ir para o anúncio.</a>
        </>
        );
    }
}

export default Ratings;