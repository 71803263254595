import React from "react";

function CookiePolicy({ localStorageKey }) {

    if (!localStorageKey) localStorageKey = "cookie.policy.accepted";
    const [ accepted, setAccepted ] =
        React.useState(localStorage.getItem(localStorageKey));

    function accept() {
        localStorage.setItem(localStorageKey,"true");
        setAccepted(true);
    }

    return !accepted && <div class="cookie-policy">
        <span>
            Usamos cookies para personalizar conteúdos e melhorar a sua experiência. 
            Ao navegar neste site, você concorda com 
            a nossa <a href="/politica-de-cookies" target="_blank">Política de Cookies</a>.
        </span>
        <button type="submit" onClick={accept}>Entendi</button>
    </div>;
}

export default CookiePolicy;
