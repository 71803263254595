import React from 'react';
import { Link } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import { SERVER_URL } from '../utils/config.js';
import { getUserData } from "../utils/JWTAuth.js";
import { firstLetters } from "../utils/convert.js";
import logo from "../img/logo-auati.svg";
import Nav from './nav';
import { SearchField } from '../pages/search';
import GlobalContext from '../globalContext.js';


class Top extends React.Component {
	
	constructor(props) {
		super(props);
		
		this.chooseStatus = this.chooseStatus.bind(this);
		this.setFilter = this.setFilter.bind(this);
		this.itemStatus = this.itemStatus.bind(this);
		this.logout = this.logout.bind(this);
		
		this.toggleNavMenu = this.toggleNavMenu.bind(this);
		this.hideNavMenu = this.hideNavMenu.bind(this);
		
		this.state = {
			statuses: [],
			choosingStatus: true,
			showingNavMenu: false,
			userData: getUserData(),
			search: ''
		};
		
	}
	
	static contextType = GlobalContext;

	logout() {
		this.props.doLogout();
		this.setState({
			showingNavMenu: false
		})
	}

	chooseStatus() {
		this.setState({ choosingStatus: !this.state.choosingStatus })
	}
	
	setFilter(status) {
		this.props.setFilterStatus(status);
		this.chooseStatus();
	}
	
	itemStatus(props) {
		const item = props.item;
		const action = props.action;
		const style = this.props.filterStatus.id === item.id ? "selected" : "";
		return (
			<li onClick={() => action(item)} className={style}>
				<img src={SERVER_URL + "/" + item.image} alt={item.text}/>
				<span>{item.text}</span>
			</li>
		);
	}
	
	toggleNavMenu() {
		this.setState({
			showingNavMenu: !this.state.showingNavMenu
		})
	}

	hideNavMenu(e){
		if(e && e.relatedTarget){
			e.relatedTarget.click();
		}
		this.setState({showingNavMenu: false});
	}
	
	render() { 
		const userData = this.props.userData;
		const path = window.location.pathname;
		if (path === '/associar-anuncios') {
			return null;
		}
		return (
			<header className="top">

					<Link to="/" className="logo">
						<img src={logo} alt="Logo Auati" title="Voltar ao início" />
					</Link> 

					<SearchField />

					<div className="settings">

						{this.props.logged && (<>
				 		<button className={"user-logged " }
							onClick={this.toggleNavMenu}
							onBlur={this.hideNavMenu}
							title={'Entrou como ' + userData.username}
				 		>
							<span>{firstLetters(userData.username)}</span>
				 		</button>
						</>)}

						{!this.props.logged && (<>
						<button type="submit"
							onClick={() => this.context.openDialog('login')}
							title='Entre para acessar sua conta'
						>
							<span><Trans i18nKey="enter">Entrar</Trans></span>
						</button>
						</>)}

					</div>
					<div className="menu">
						<button 
							className="menu" 
							title="Abrir o menu de navegação"
							onClick={this.toggleNavMenu}
							onBlur={this.hideNavMenu}
						></button>
						{this.state.showingNavMenu && <Nav logout={this.logout} logged={this.props.logged} />}
					</div>
			</header>
		);
	}
}

export default withTranslation()(Top);