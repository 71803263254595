import React from 'react'
import AdList from "../components/adlist"


function Ads() {

    return <div>
        <section className="adcover">
            <AdList />
        </section>
    </div>;
}

export default Ads;
