import React from 'react';
import { Link } from 'react-router-dom';

class Error404 extends React.Component {

    componentDidMount() {
		document.title = "AUATI - Página não encontrada";
    }
    
    render() {
        return (
            <main>
                <h1>
                    <span>Ops!</span>
                </h1>
                <section>
                    <h3>Página não encontrada.</h3>
                    <p>Confira o endereço digitado.</p>
                    <p><span>Quer voltar para o início?</span>
                        <Link to='/'> Clique aqui.</Link>
                    </p>
                </section>
            </main>
        )
    }
}

export default Error404;