import React from 'react';
// import { Link } from 'react-router-dom';

class Galery extends React.Component {

    render() {
        const ad = this.props.ad;
        return (ad.images && ad.images.length > 0 &&
        <ul className="galery">
            {ad.images.map(o => (<li key={o.name}><img alt={o.description} src={"images/image/"+o.name} /></li>))}
        </ul>
        )
    }
}

export default Galery;