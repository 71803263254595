import React from 'react';

import { Record, FieldControl } from '../../components/fields/Record';
import adFieldDefs from '../../fieldDefs/ad';
import { addAd, getAds, setAd } from '../../utils/service';
import GlobalContext from '../../globalContext';
import Categories from '../../components/categories';
import { loadImage, removeAllImages } from '../../utils/idb';
import controlTemplates from '../../components/controlTemplates';
import { Document, useEffect } from "../../utils/reactUtils";
import { Switch, Case, Otherwise } from "../../components/switch";

function AdEditor({ mode, adParam, onSave, onBackClick }) {

    const context = React.useContext(GlobalContext);
    const [ state, setState ] = React.useState({
        name: "loading", mode
    });

    useEffect(ifMounted => {
        if (state.name !== "loading") {
            return;
        }
        switch (state.mode) {
            case "new":
                let data;
                try {
                    data = JSON.parse(localStorage.getItem("novo-anuncio"));
                } catch(ex) {
                    data = {};
                }
                setState({ ...state, name: "ready",  data });
                break;

            case "edit":
                getAds(state.param)
                    .then(function checkResult(result) {
                        return (result && result.success) ? result.data :
                            Promise.reject(result.data || "Erro ao buscar informações");
                    })
                    .then(function checkDataLength(data) {
                        return data.length === 1 ? data[0] :
                            Promise.reject("Erro ao identificar anúncio");
                    })
                    .then(({ poster, images, ...data }) => ({
                        ...data,
                        poster: [{ src: `images/ad/${poster}` }],
                        images: images.map(
                            ({name}) => ({ src: `images/image/${name}`}))
                    }))
                    .then(data => ({ ...state, name: "ready", data: data }))
                    .catch(error => ({ ...state, name: "error", data: error }))
                    .then(ifMounted(setState));
                break;
            default:
                break;
        }
    }, [ state ]);
    if ((state.param !== adParam) || (state.mode !== mode)) {
        setState({ name: "loading", param: adParam, mode });
    }

    function loadImageArray(imageArray) {
        return Promise.all((imageArray || []).map(
            image => image.id ? loadImage(image.id) : Promise.resolve(image)));
    }


	function handleSubmit(event) {

        const { data, formData } = event;
        const { poster, images } = data;

		context.setLoading(true);

        function addImagesToFormData(fieldName, images) {
            formData.delete(fieldName);
            formData.delete(fieldName.replace(/\[\]$/, ""));
            images.forEach(function addImage({ src, data, name }) {
                if (data) {
                    formData.append(fieldName, data, name);
                } else {
                    formData.append(fieldName, src.replace(/.*\//, ""));
                }
            });
        }

        formData.append('agree', data.agree);
        Promise.all([loadImageArray(poster), loadImageArray(images)])
            .then(function setPosterAndImages([ poster, images]) {
                addImagesToFormData("poster", poster);
                addImagesToFormData("images[]", images);
            })
            .then(function sendFormData() {
                return (data.id) ? setAd(formData, data.id) : addAd(formData);
            })
            .then(function checkSendResult(result) {
                return (result && result.success) ?
                    result.data :
                    Promise.reject(result.data || 'Não foi possível salvar as informações.');
            })
            .then(function cleanup(data) {
                localStorage.removeItem("novo-anuncio");
                removeAllImages();
                return data;
            })
            .then(function reportSuccess(data) {
                context.showSuccess('Seus dados foram salvos');
                if (typeof onSave === "function") {
                    onSave(data[0]);
                }
            })
            .catch(function showErrorOnFailure(reason) {
                context.showError(String(reason));
            })
            .then(function notifyEnd() {
                context.setLoading(false);
            });
	}

    return <main className='newad'>
        <section>
        <Switch value={state.name}>
            <Case when="loading">
                <div>Carregando...</div>
            </Case>
            <Case when="saving">
                <div>Gravando...</div>
            </Case>
            <Case when="ready">
                <Document title={`Auati -${state.data && state.data.title} - Editar`}>
                <h1>{ mode === "edit" ? "Editar anúncio" : "Novo anúncio"}</h1>
                <Record.Form
                    onSubmit={handleSubmit} encType="multipart/form-data" method="post"
                    localStorageKey={(mode === "new") ? "novo-anuncio" : undefined}
                    defaultValue={state.data}
                    fieldDefs={adFieldDefs}
                    templates={controlTemplates}
					beforeUnloadMessage="Deseja sair antes de concluir?"
                >
                    <div className='fields'>
                        <FieldControl fieldName="title" />
                        <FieldControl fieldName="offer"/>
                        <FieldControl fieldName="moreinformation"/>
                        <FieldControl fieldName="id_category"/>
                        <FieldControl fieldName="remote"/>
                        <FieldControl fieldName="poster" />
                        <FieldControl fieldName="images" />

                        <div className="field choice want_categories">
                            <label htmlFor="want_categories">Tenho interesse em trocar meu trabalho por</label>
                            <p>Escolha as categorias de serviços que deseja receber propostas.</p>
                            <Categories fieldName="want_categories" required={true}/>
                        </div>
                        <FieldControl fieldName="want"/>
                        <FieldControl fieldName="agree"/>
                    </div>
                    <button type='submit'>Publicar o anúncio</button>
                    <button type='button' onClick={onBackClick}>Voltar</button>
                </Record.Form>
                </Document>
            </Case>
            <Otherwise>
                <div>Ocorreu um erro na aplicação</div>
            </Otherwise>
        </Switch>
        </section>
    </main>;
}

export default AdEditor;
