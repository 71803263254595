import React from 'react';


export default class Dialog extends React.Component {

	constructor(props) {
		super(props);
		this.state = {};
		this.closeDialog = this.closeDialog.bind(this);
		this.closeByBgClick = this.closeByBgClick.bind(this);
		this.closeByKeyboard = this.closeByKeyboard.bind(this);
		this.state = {
			closing: false
		}
	}

	async closeDialog() {
		document.body.classList.toggle("dialoguing");
		this.setState(
			{closing: true}, 
			() => setTimeout(this.props.onClose, 500)
		);
	}

	closeByBgClick() {
		if (!this.props.data.modal) {
			this.closeDialog();
		}
	}
	
	closeByKeyboard(event) {
		if (event.key === 'Escape') {
			this.closeDialog();
		}
	}

	componentDidMount(){
		document.addEventListener("keydown", this.closeByKeyboard, false);
		document.body.classList.toggle("dialoguing");
	}

	componentWillUnmount(){
		document.removeEventListener("keydown", this.closeByKeyboard, false);
	}

	render() {
		const data = this.props.data;
		const closingClass = this.state.closing ? ' closing' : '';
		return (
			<div className={"dialog " + data.name + closingClass} onKeyUp={this.closeByKeyboard}>
				<div className="bg" onClick={this.closeByBgClick} />
				<div className="window">
					<header>
						<h3>{data.title}</h3>
						{!data.modal && (
						<button className="close" onClick={this.closeDialog} title="fechar">
						</button>
						)}
					</header>
					<div className="content">
						{data.content}
					</div>
				</div>
			</div>
		);
	}
} 