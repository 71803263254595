import React from "react";
import {
    Switch,
    Route
} from 'react-router-dom';

import Recover from "./pages/recover";
import Confirm from "./components/confirm";
import Start from "./pages/start";
import Ad from "./pages/ad";
import Person from "./pages/person";
import AdDone from "./pages/addone";
import Ads from "./pages/ads";
import Myaccount from "./pages/myaccount";
import Users from "./pages/users";
import User from "./pages/user";
import Newuser from "./pages/newuser";
import Error404 from "./pages/error404";
import Notifications from './pages/notifications';
import Messages from './pages/messages';
import Favorites from './pages/favorites';
import Invite from './pages/invite';
import Terms from './pages/terms';
import Help from './pages/help';
import About from './pages/about';
import VerifyEmail from './pages/verify-email';
import LoginPage from './pages/login';
import LocalStorageMonitor from "./components/fields/localStorageMonitor";
import Category from './pages/category';
import { Search } from './pages/search';
import Cookies from './pages/cookies';
import Myauatis from './pages/myauatis';

export default function Routes ({that}) {
    return (
        <Switch>
            <Route exact path="/">
                <Start />
            </Route>

            <Route path="/local-storage/:localStorageKey">
                <LocalStorageMonitor />
            </Route>

            <Route path="/recover/:hash">
                <Recover 
                setLoading={that.setLoading} 
                loading={that.state.loading}
                openLoginDialog={that.openLoginDialog}
                closeLoginDialog={that.closeLoginDialog}
                />
            </Route>

            <Route path="/verify-email/:username?">
                <VerifyEmail 
                setLoading={that.setLoading} 
                loading={that.state.loading}
                />
            </Route>

            <Route path="/confirm/:hash">
                <Confirm 
                setLoading={that.setLoading} 
                loading={that.state.loading}
                />
            </Route>

            <Route path="/signup">
                <Newuser 
                logged={that.state.logged} 
                setLoginStatus={that.setLoginStatus} 
                loading={that.state.loading}
                setLoading={that.setLoading} 
                userData={that.state.userData}
                />
            </Route>

            <Route path="/login">
                <LoginPage 
                action={that.doLogin} 
                email={that.state.email}
                setEmail={that.setEmail}
                />
            </Route>

            <Route path="/notificacoes">
                <Notifications 
                setLoading={that.setLoading} 
                loading={that.state.loading}
                />
            </Route>
                    
            <Route path="/mensagens">
                <Messages 
                setLoading={that.setLoading} 
                loading={that.state.loading}
                />
            </Route>

            <Route path="/meus-auatis">
                <Myauatis />
            </Route>
                    
            <Route  path="/favoritos">
                <Favorites 
                setLoading={that.setLoading} 
                loading={that.state.loading}
                />
            </Route>

            <Route  path="/convidar-amigos">
                <Invite 
                setLoading={that.setLoading} 
                loading={that.state.loading}
                />
            </Route>

            <Route  path="/termos-de-uso">
                <Terms 
                setLoading={that.setLoading} 
                loading={that.state.loading}
                />
            </Route>
                        
            <Route  path="/politica-de-cookies">
                <Cookies/>
            </Route>
                        
            <Route  path="/ajuda">
                <Help 
                setLoading={that.setLoading} 
                loading={that.state.loading}
                />
            </Route>
                        
            <Route  path="/sobre-o-projeto">
                <About 
                setLoading={that.setLoading} 
                loading={that.state.loading}
                />
            </Route>

            <Route path="/c/:category">
                <Category />
            </Route>

            <Route path="/s/:search">
                <Search />
            </Route>
                                
            <Route path="/ads">
                <Ads 
                filterStatus={that.state.filterStatus}
                setFilterStatus={that.setFilterStatus} 
                logged={that.state.logged} 
                setLoginStatus={that.setLoginStatus} 
                loading={that.state.loading}
                setLoading={that.setLoading} 
                search={that.state.search}
                userData={that.state.userData}
                />
            </Route>

            <Route path="/anuncio-concluido">
                <AdDone
                />
            </Route>

            <Route path="/minha-conta/:activeTabId?/:tabAction?">
                <Myaccount
                logged={that.state.logged}
                setLoginStatus={that.setLoginStatus}
                loading={that.state.loading}
                setLoading={that.setLoading}
                />
            </Route>
            <Route path="/usuarios">
                <Users 
                logged={that.state.logged} 
                setLoginStatus={that.setLoginStatus} 
                loading={that.state.loading}
                setLoading={that.setLoading} 
                userData={that.state.userData}
                />
            </Route>
            <Route path="/usuario">
                <User 
                logged={that.state.logged} 
                loading={that.state.loading}
                setLoading={that.setLoading} 
                userData={that.state.userData}
                />
            </Route>
            <Route path="/:username/:ad_slug/:mode?">
                    <Ad
                    setLoading={that.setLoading}
                    loading={that.state.loading}
                    />
            </Route>
            <Route exact path="/:user">
                <Person 
                setLoading={that.setLoading} 
                loading={that.state.loading}
                />
            </Route>
            <Route>
                <Error404 />
            </Route>
        </Switch>
    )
}