import axios from 'axios';
import { SERVER_URL } from './config.js';
import { getToken } from './JWTAuth.js';

const getItems = async (path) => {
    const endpoint = SERVER_URL + path;
    const token = "Bearer "+getToken();
    try {
        let response = await axios({
            url: endpoint,
            method: 'GET',
            headers: {
                'Authorization': token
            }
        });
        return (response.data);
    } 
    catch(e) { console.error(e); }
}

const getAds = async param => { 
    param = param || '';
    if (typeof param === 'object') {
        param = Object.fromEntries(
            Object.entries(param)
                .filter(([ key, value ]) => value !== undefined)
        );
        param = "?" + new URLSearchParams(param).toString();
    } else {
        param = param ? ("/" + param) : "";
    }
    return getItems('ads' + param);
};

const getMyAds = async () => getItems('myads');
const getFavorites = async () => getItems('favorites');
const getLikes = async id => getItems('likes' + (id ? '/' + id : ''));
const getMessages = async id => getItems('messages' + (id ? '/' + id : ''));
const getPerson = async id => getItems('person' + (id ? '/' + id : ''));
const getCategories = async id => getItems('categories' + (id ? '/' + id : ''));
const getCities = async uf => getItems('cities/' + uf);
const getStates = async uf => getItems('states');
const getCountries = async uf => getItems('countries');
const getAuatis = async (id_person) => getItems("auatis?id_person=" + id_person);
const getUsers = async id => getItems('usuarios' + (id ? '/' + id : ''));
const getNotifications = async id => getItems('user/notifications' + (id ? '/' + id : ''));
const getWPAds = async id => getItems('wpads' + (id ? '/' + id : ''));
const getHistory = async id => getItems('historico' + (id ? '/' + id : ''));
    

const delItem = async (id, path) => {
    if (!id) {
        return { success: false, data: 'Informações insuficientes'};
    }
    path = path + "/" + id;
    const endpoint = SERVER_URL+path;
    const token = "Bearer "+getToken();
    let data = {
        id: id,
        _METHOD: "DELETE"
    }
    try {
        let response = await axios({
            validateStatus: false,
            url: endpoint,
            method: 'DELETE',
            data: data,
            headers: {
                'Authorization': token
            }
        });
        return (response.data);
    } 
    catch(e) { return e; }
}

const delAuatiRequest = (id) => delItem(id, "auatirequest");    

const setItem = async (data, id, path) => {
    if (!id) {
        return { success: false, data: 'Informações insuficientes'};
    }
    path = path + "/" + id;
    const endpoint = SERVER_URL+path;
    const token = "Bearer "+getToken();
	data.append("_METHOD", "PUT");
    try {
        let response = await axios({
            validateStatus: false,
            url: endpoint,
            method: 'POST',
            data: data,
            headers: {
                'Authorization': token
            }
        });
        return (response.data);
    } 
    catch(e) { return e; }
}

const setAd = (data, id) => setItem(data, id, "ads");
const delAd = (id) => delItem(id, "ads");
const setLike = (data, id) => setItem(data, id, "likes");
const setAuati = (data, id) => setItem(data, id, "auati");
const setUser = async (data) => {
    const endpoint = SERVER_URL+"myprofile";
    const token = "Bearer "+getToken();
	data.append("_METHOD", "PUT");
    try {
        let response = await axios({
            validateStatus: false,
            url: endpoint,
            method: 'POST',
            data: data,
            headers: {
                'Authorization': token
            }
        });
        return (response.data);
    }
    catch(e) { return e; }
};

const addItem = async (data, path, auth = true, extra = {}) => {
    const endpoint = SERVER_URL+path;
    data.append("_METHOD", "POST");
    const config = {
        validateStatus: false,
        url: endpoint,
        method: 'POST',
        data: data,
        headers: {
            'Content-Type': "multipart/form-data"
        }
    };
    for (const key in extra) {
        config[key] = extra[key];
    }
    if (auth) {
        const token = "Bearer "+getToken();
        config.headers.Authorization = token;
    }
    try {
        let response = await axios(config);
        return (response.data);
    } 
    catch(e) { console.error(e); }
}
const addAuatiRequest = (data) => addItem(data, "auatirequest");
const addMessage = (data) => addItem(data, "messages");
const addAd = (data) => addItem(data, "ads");

const addUser = (data) => addItem(data, "users");

const getClassificacao = () => {

}

const getStatuses = async (setResponse) => {
    const endpoint = `${SERVER_URL}/statuses`;
    const token = "Bearer "+getToken();

    try {
        let response = await axios({
            url: endpoint,
            method: 'GET',
            headers: {
                'Authorization': token
            }
        });
        return (response.data);
    } 
    catch(e) { console.error(e); }
}

const patchItem = async (formdata, id, path, prop) => {
    if (!formdata || !id || !path || !prop) {
        return { success: false, data: 'Informações insuficientes'};
    }
    const endpoint = SERVER_URL + path + "/" + id + "/" + prop;
    const token = "Bearer "+getToken();
	formdata.append("_METHOD", "PATCH");
    
    var object = {};
    formdata.forEach((value, key) => object[key] = value);
    
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Authorization': token
        },
        body: formdata
    };
    try {
        const response = await fetch(endpoint, requestOptions);
        if (!response.ok) {
            throw new Error('Erro ao enviar a requisição');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
    }
}

const patchAvatar = (data, id) => patchItem(data, id, "users", "avatar");

export {
    getAds,
    getMyAds,
    getLikes,
    getMessages,
    getNotifications, 
    getPerson,
    getCategories,
    getAuatis,
    delAuatiRequest,
    setLike,
    setAuati,
    addMessage,
    addAd,
    setAd,
    delAd,
    patchAvatar,

    getClassificacao,
    getStatuses,
    getWPAds,
    addAuatiRequest,
    getUsers,
    addUser,
    setUser,
    getHistory,
    getFavorites,
    getCities,
    getStates,
	getCountries
}
