import React from 'react';
import { formatDate } from '../utils/convert';

class About extends React.Component {

    Verification({data, type}) {
        const column = type+"_verified";
        const is_on = !!data[column];
        const style = type + (is_on ? " on" : "");
        let name = type;

        switch (type) {
            case 'email':
                name = "E-mail";
                break;
            case 'tel':
                name = "Telefone";
                break;
            case 'doc':
                name = "Documento com foto";
                break;
            default: 
                return "";            
        }
        let title = name + (is_on ? "" : " não") +" verificado" + (is_on ? " em " + formatDate(data[column], "short") : "") + ".";

        return (
            <div 
                className={style} 
                title={title}>
            </div>
        );
    }
    
    render() {
        const person = this.props.person;
        const links = this.props.links;
        const avatar = "images/person/" + (person.avatar || "placeholder-person.svg");
        
        return (
            <>
            <header>
                <figure>
                    <img 
                    src={avatar} 
                    alt={'Foto de '+person.fullname} />
                </figure>
                <h2>{person.fullname}</h2>
                <small>Desde {formatDate(person.date_created)}</small>
                <div className='verification'>
                    <this.Verification data={person.user} type={'email'}></this.Verification>
                    <this.Verification data={person.user} type={'tel'}></this.Verification>
                    <this.Verification data={person} type={'doc'}></this.Verification>
                </div>
            </header>
            <p>{person.about_me}</p>
            <a className='button' href={`/${person.nickname}`}>
                Mais sobre {person.fullname}
            </a>
            {links.length > 0 && (
            <div className='links'>
                <h4>Redes sociais</h4>
                {links.map(o => (<a key={o.id} className={o.type} target="_blank" 
                    rel="noopener noreferrer" href={o.urlprefix+o.value} title={o.name}>
                    <span>{o.name}</span>
                </a>))}
            </div>
            )}
            </>
        )
    }
}

export default About;