import React from 'react';
import GlobalContext from '../globalContext.js';
import { login } from "../utils/JWTAuth.js";


class Formlogin extends React.Component {

	constructor(props) {
		super (props);
	
		this.login = this.login.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.forgetPassword = this.forgetPassword.bind(this);
		this.sendNewVerificationEmail = this.sendNewVerificationEmail.bind(this);
		this.togglePassword = this.togglePassword.bind(this);
		this.signup = this.signup.bind(this);
		this.getTokenAndSendData = this.getTokenAndSendData.bind(this);

		this.state = {
			email: '',
			password: '',
			showPassword: false,
			error: ''
		};
	}

	static contextType = GlobalContext;

	togglePassword() {
		this.setState({showPassword: !this.state.showPassword});
	}

	handleInputChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		this.setState({
			[name]: value
		});
		if (name === 'email' && this.props.setEmail) {
			this.props.setEmail(value);
		}
	}

	forgetPassword() {
		this.props.setEmail(this.state.email);
		this.context.openDialog('forget');
	}

	async sendNewVerificationEmail() {
		document.location.assign('/verify-email/' + this.state);
	}

	signup() {
		const path = window.location.pathname.split("/")[1];
		this.props.setEmail(this.state.email);
		if (path !== 'signup') {
			this.context.openDialog('signup');
		} else {
			this.props.close();
		}
	}

	async getTokenAndSendData(formData) {
		await window.grecaptcha.ready(async () => {
			const token = await Promise.resolve(window.grecaptcha.execute('6LciP68ZAAAAAPYLrM0yiYxTwt-QtDjUF7Gxxbry', {action: 'login'}));
			formData["grecaptcha_token"] = token;
			const result = await login(formData);
			const destination = localStorage.getItem('destination');
			if (result && result.success) {
				this.context.closeDialog('login');
				if (destination) {
					return window.location.href = destination;
				}
			} else {
				const error = result && result.data;
				let errormsg = '';
				if (typeof result.data === 'object') {
					if (error.code === 'email_not_verified') {
						setTimeout(() => {
							document.location.assign("/verify-email/" + error.user.username);
						}, 5000);
					}
					this.setState({ error: error.code });
					errormsg = error.text;
				}
				this.context.showError(errormsg || 'Estamos com algumas dificuldades. Não conseguimos entrar.');
			}
			this.props.action(result);
		});
	}

	async login(event){
		event.preventDefault();
	    let info = {
	    	email: this.state.email,
	    	password: this.state.password
		};
		await this.getTokenAndSendData(info);
	 }

	render() {
		const type = this.state.showPassword ? 'text' : 'password';
		return (
			<form onSubmit={this.login}>
				<fieldset>
					<div className='fields'>
						<div className="field email">
							<label htmlFor="email">E-mail:</label>
							<input 
								name='email' 
								id='email'
								type='email'
								required
								autoComplete="username"
								placeholder="Insira seu e-mail"
								value={this.state.email} 
								onChange={this.handleInputChange} 
							/>
						</div>
						<div className="field password">
							<label htmlFor="password">Senha:</label>
							<input 
								name='password' 
								id='password'
								type={type}
								required
								placeholder="Insira sua senha"
								autoComplete="current-password"
								value={this.state.password} 
								onChange={this.handleInputChange} />
							<button type='button' className={'togglepass '+type} onClick={this.togglePassword}></button>
						</div>
						
						{this.state.error==='email_not_verified' && (<p className='error'>
							Você ainda não confirmou seu e-mail.
							<button type="button" className="link verify-email" onClick={this.sendNewVerificationEmail}>
								<span>Enviar novo e-mail de verificação</span>
							</button>
						</p>)}
							
					</div>
				</fieldset>
				<footer>
					<div className="buttons">
						<button type="submit">
							<span>Entrar</span>
						</button>
						<button type="button" className="link forget-password" onClick={this.forgetPassword}>
							<span>Esqueci minha senha</span>
						</button>
						<button type="button" className="link signup" onClick={this.signup}>
							<span>Criar uma conta</span>
						</button>
					</div>
				</footer>
			</form>
		);
	}
}

export default Formlogin;