import React from "react";
import { controls as defaultControls } from './controls';
import { useWindowEventListener } from '../../utils/reactUtils';

const RecordContext = React.createContext();

function recordReducer(state, action) {
	switch (action.type) {
		case "setFieldValue":
			return { ...state, [action.fieldName]: action.value };
		case "setData":
            return { ...state, ...action.value };
		default:
			console.error("Unknown action type ", action.type);
			return state;
	}
}

function getDefaultValue(defaultValue, localStorageKey) {
	if (defaultValue) {
		return defaultValue;
	}
	try {
		if (localStorageKey) {
			const item = localStorage.getItem(localStorageKey);
			if (item) {
				return JSON.parse(item);
			}
		}
	} catch(ex) {

	}
	return {};
}

function defaultTemplate({ renderControl, ...props }) {
    return renderControl(props);
}

/*
 * Utilitário para renderizar um campo no contexto de um Record.
 */
function FieldControl({
    templateName, fieldName, ...forwardProps
}) {
    const record = Record.use();
    const fieldDef = record.getFieldDef(fieldName) || {};
    const fieldValue = record.getFieldValue(fieldName);
    const template = record.getTemplate(templateName || "default") ||
            defaultTemplate;
    const { controlType, type } = forwardProps;
    const control = record.getControl(
        controlType || type || fieldDef.controlType || fieldDef.type);

    if (template === defaultTemplate) {
        console.warn(`Template not found for template name ${templateName}. Using default one, which just renders control`);
    }

    if (!fieldName) {
        throw new Error(`FieldControl must have a fieldName property`);
    }
    if (!control) {
        throw new Error(`No control found for field ${fieldName}, Check controlType property of FieldControl`);
    }

    const templateProps = {
        record, fieldValue, fieldName,
        ...fieldDef, ...control, ...forwardProps
    };
    return template(templateProps);
}

function Record({
    defaultValue, fieldDefs, onRecordChange, children, localStorageKey,
    beforeUnloadWindow, templates, controls, setDirty, beforeUnloadMessage
}) {

	const startingValue = React.useMemo(() =>
		getDefaultValue(defaultValue, localStorageKey),
		[ defaultValue, localStorageKey ]);
	const [ data, dispatchRecordAction ] = React.useReducer(
			recordReducer, startingValue);
	const dirty = React.useMemo(() => {
		const defaultProps = Object.entries(startingValue);
		const dataProps = Object.entries(data);
		return (dataProps.length !== defaultProps.length) ||
				defaultProps.some(([key, value]) => value !== data[key]);
	}, [startingValue, data]);
	React.useMemo(() => {
		(typeof setDirty === "function") && setDirty(dirty);
	}, [dirty, setDirty]);
	useWindowEventListener("beforeunload", beforeUnloadWindow ||
		(dirty && beforeUnloadMessage && function showUnloadMessage(event) {
            event.preventDefault();
            return beforeUnloadMessage;
        }));
	const record = {
		getData: () => data,
        setData: value => dispatchRecordAction({ type: "setData", value }),
		getFieldValue: fieldName => data[fieldName],
		setFieldValue: (fieldName, value) => dispatchRecordAction({
			type: "setFieldValue", fieldName, value
		}),
		getFieldDef: fieldName => {
            const fieldDef = fieldDefs && fieldDefs[fieldName];
            return (typeof fieldDef === "function") ? fieldDef(data || {}) :
                fieldDef;
        },
		changeFieldValue: fieldName => event =>
			record.setFieldValue(fieldName, event.target.value),
        getTemplate: templateName => (templates && templates[templateName]),
        getControl: controlType => (controls && controls[controlType]) ||
            defaultControls[controlType]
	};

	React.useEffect(() => {
		if (onRecordChange) {
			onRecordChange(data);
		}
	}, [ data, onRecordChange ]);

	React.useEffect(() => {
        if (localStorageKey) {
            localStorage[localStorageKey] = JSON.stringify(data);
        }
	}, [ localStorageKey, data ]);


	return <RecordContext.Provider value={record}>
		{children}
	</RecordContext.Provider>;
}

Record.use = function useRecord() {
	return React.useContext(RecordContext);
};

function RecordFormContainer({ formProps, onSubmit, children }) {

    const record = Record.use();

    function onSubmitForm(event) {
        if (onSubmit) {
            event.preventDefault();
            onSubmit({
                record, data: record.getData(),
                formData: new FormData(event.target),
                event
            });
        }
    }

    return <form {...formProps} onSubmit={onSubmitForm}>
        { children }
    </form>;
}

Record.Form = function RecordForm(props) {
	const {
		defaultValue, fieldDefs, onRecordChange, children, localStorageKey,
        beforeUnloadWindow, onSubmit, templates, controls, setDirty,
		beforeUnloadMessage,
		...formProps
	} = props;
	const recordProps = {
        defaultValue, fieldDefs, onRecordChange, children, localStorageKey,
        beforeUnloadWindow, templates, controls, setDirty, beforeUnloadMessage
    };
	return <Record {...recordProps}>
        <RecordFormContainer formProps={formProps} onSubmit={onSubmit}>
            { children }
        </RecordFormContainer>
    </Record>;
};

export default Record;
export { Record, FieldControl };
