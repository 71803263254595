import React from 'react';

import GlobalContext from "../globalContext";
import { SearchContextProvider } from "../pages/search";
import { SystemMessageContextProvider, useMessageContext } from "./msg";
import { Document } from "../utils/reactUtils";


function InnerProvider({ global, children }) {
    return <GlobalContext.Provider value={{...global, ...useMessageContext()}}>
        { children }
    </GlobalContext.Provider>;
}

function ContextProviders({ searchPath, global, children }) {
    return <Document>
        <SystemMessageContextProvider>
            <SearchContextProvider path={searchPath}>
                <InnerProvider global={global}>{ children }</InnerProvider>
            </SearchContextProvider>
        </SystemMessageContextProvider>
    </Document>;
}

export default ContextProviders;
