import React from "react";

const SwitchContext = React.createContext();

function CaseOrOtherwise(name, children) {
    const context = React.useContext(SwitchContext);
    if (!context) {
        throw new Error(name + " component can be used only as an immediate child of Switch component");
    }
    return <SwitchContext.Provider value={null}>
        {children}
    </SwitchContext.Provider>;
}

function Case({ children }) {
    return CaseOrOtherwise("Case", children);
}

function Otherwise({ children }) {
    return CaseOrOtherwise("Otherwise", children);
}

/**
 * Componente container que renderiza somente um de seus filhos.
 */
function Switch(props) {

    const childrenArray = Array(props.children).flat();
    const otherwise =
        (childrenArray[childrenArray.length - 1].type === Otherwise) &&
        childrenArray.pop();

    childrenArray.forEach(child => {
        if (child.type === Otherwise) {
            throw new Error("Otherwise element, when present must be the last child of Switch");
        }
        if (child.type !== Case) {
            throw new Error("Switch elements can only have Case elements as their children");
        }
    });
    const value = props.value;
    const activeComponent = (Object.hasOwn(props, "value")) ?
        // value has been specified. Test when properties against it
        function activeComponent({ props: { when } }) {
            return (when === value) ||
                ((typeof when === "function") && when(value));
        } :
        // value has been not specified. First truth when property wins
        function activeComponent({ props: { when } }) {
            return (typeof when === "function") ? when() : when;
        };

    return <SwitchContext.Provider value={{}}>
        {childrenArray.find(activeComponent) || otherwise}
    </SwitchContext.Provider >;
}

export { Switch, Case, Otherwise };
