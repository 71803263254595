import React from "react";
import { firstLetters } from "../utils/convert";

export default class Avatar extends React.Component {
    constructor(props) {
        super(props);
        this.action = this.action.bind(this);
    }

    action() {
        if (this.props.action) {
            this.props.action();
        }
        return false;
    }

    render() {
        const props = this.props;
        const person = this.props.person;
        const classes = 'avatar' + (this.props.action ? ' pointer' : '');
		var src, fl;

        function getTitle() {
            if (props.edit) {
                if (person && person.avatar) {
                    return "Alterar foto";
                } else {
                    return "Adicionar foto";
                }
            }
        }

        return (
            <figure className={classes} onClick={this.action}  title={getTitle()}>
            {
				(!person &&
					<img src='../img/question.svg' alt='' title='Não foi atribuído.'/>
				) ||
				((src = person.avatar) &&
					<img src={'/images/person/' + src} alt=''/>
				) ||
				((fl = firstLetters(person.username)) &&
					<span>{fl}</span>
				) || ""
			}
            {this.props.edit && (
                <div className='edit'></div>
            )}
            </figure>
        );
    }
}