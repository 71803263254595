import React from 'react';
import logo from "../img/logo-auati.svg";

class Cookies extends React.Component {
    render() {
        return  (
			<main className="cookies">
				<article>

                    <h1>Política de Cookies</h1>


                    <div className='abstract'>
                        <p>
                            Olá, nós valorizamos sua privacidade e por isso apresentamos a 
                            Política de Cookies do site Auati. 
                        </p>
                        <p>
                            O objetivo desta política fornecer a você, usuário do site Auati, 
                            informações claras e acessíveis sobre os cookies que utilizamos 
                            e a forma como isso afeta sua experiência como usuário. 
                        </p>
                        <p>
                            A presente Política de Cookies é complementar aos nossos 
                            Termos de Uso, que você pode 
                            acessar <a href="/termos-de-uso">clicando aqui</a>.  
                        </p>
                    </div>


                    <h2>
                        1. O que são cookies?
                    </h2>
                    <p>
                        Cookies são ferramentas essenciais na navegação online. 
                        Consistem em arquivos que identificam a interação entre você
                        e os sites e que são transferidos para o seu equipamento 
                        (computador, tablet ou smartphone) visando otimizar e personalizar 
                        a sua experiência de navegação. 
                    </p>
                    <p>
                        O termo “cookie” pode ser usado para descrever uma série de 
                        tecnologias, incluindo, mas não se limitando a pixel tags, 
                        web beacons, armazenamento local e identificadores de dispositivo móvel.
                    </p>
                    <p>
                        O Auati utiliza tanto cookies de sessão quanto cookies 
                        persistentes. Os cookies de sessão coletam informações 
                        que não permitem a identificação do usuário e são excluídos 
                        automaticamente quando você fecha a janela do seu navegador.
                    </p>
                    <p>
                        Já os cookies persistentes coletam informações que possibilitam a 
                        identificação do usuário e são armazenados no seu computador ou 
                        dispositivo móvel até sua expiração.
                    </p>
                    <p>
                        Além disso, os cookies utilizados nos Sites podem ser 
                        “proprietários”, isto é, cookies definidos por nós - ou por 
                        um terceiro em nosso nome - ou “de terceiros”, que permitem que 
                        recursos ou funcionalidades de terceiros confiáveis sejam 
                        fornecidos nos Sites ou por meio deles ou em serviços que você 
                        utiliza como anúncios, conteúdo interativo ou analytics. 
                    </p>
                    <p>
                        Eses terceiros podem reconhecer o seu dispositivo quando você 
                        navega no Auati ou em outros sites.
                    </p>

                    <h2>
                        2. Como os cookies são utilizados pelo Auati?
                    </h2>
                    <p>
                        Os cookies são utilizados de acordo com seus tipos.
                    </p>
                    <h3>
                        Cookies essenciais
                    </h3>
                    <p>
                        São os cookies necessários para o funcionamento do site e 
                        prestação dos serviços a você. Sem eles, o site não 
                        funciona tão bem como nós gostaríamos e podemos não ser 
                        capazes de fornecer determinados serviços ou recursos 
                        que dependem desses cookies.
                    </p>

                    <h3>
                        Cookies de Preferências
                    </h3>
                    <p>
                        Os cookies de preferências coletam informações sobre suas 
                        escolhas e preferências, permitindo que o site se lembre do 
                        idioma, localização, dispositivo utilizado ou outras 
                        configurações, para que sua experiência seja personalizada 
                        de acordo com essas preferências.
                    </p>

                    <h3>
                        Cookies Analíticos
                    </h3>
                    <p>
                        Os cookies analíticos coletam informações sobre a utilização do 
                        site, permitindo-nos aperfeiçoar o seu funcionamento. Mostram, 
                        por exemplo, quais são as páginas mais visitadas, ajudam-nos a 
                        registar quaisquer dificuldades que os usuários sintam na navegação, 
                        e mostram-nos se a nossa publicidade é eficaz ou não. Isso nos 
                        possibilita ver os padrões de uso do site de forma anônima e agregada. 
                    </p>
                    <h3>
                        Cookies de Publicidade
                    </h3>
                    <p>
                        Os cookies de publicidade são utilizados para fins de 
                        marketing, permitindo o direcionamento dos anúncios que 
                        aparecem para você com base nos seus interesses. 
                        O objetivo é mostrar anúncios de serviços mais relevantes 
                        para você, melhorar os relatórios sobre o desempenho dos 
                        anúncios e evitar a exibição de anúncios que você já viu.
                    </p>
                    <h2>
                        3. Como Gerir Cookies?
                    </h2>
                    <p>
                        A maioria dos navegadores de Internet vem configurada para 
                        aceitar cookies automaticamente, mas você pode mudar as 
                        configurações para bloqueá-los ou receber um alerta sempre 
                        que um cookie for enviado para o seu dispositivo. 
                    </p>
                    <p>
                        Consulte a opção de ajuda do seu navegador para obter mais 
                        informações. Aqui estão alguns links para os navegadores 
                        mais populares:
                    </p>
                    <p class="TagPMarkdown-b2g63g-0 xNtEk">
                        <a href="https://support.microsoft.com/pt-br/topic/alterar-as-configura%C3%A7%C3%B5es-de-seguran%C3%A7a-e-privacidade-no-internet-explorer-11-9528b011-664c-b771-d757-43a2b78b2afe#ie=ie-11">Internet Explorer</a>
                        <br />
                        <a href="https://support.mozilla.org/pt-BR/kb/cookies-informacoes-sites-armazenam-no-computador?redirectlocale=pt-BR&amp;redirectslug=cookies-informacoes-armazenadas-por-sites-em-seu-c">Firefox</a>
                        <br />
                        <a href="https://support.google.com/chrome/answer/95647?ref_topic=3434352&amp;hl=pt-BR">Chrome</a>
                        <br />
                        <a href="https://support.apple.com/pt-br/HT201265">Safari Web e iOS</a>
                    </p>
                    <p>
                        Se você desativar ou remover os cookies, é importante 
                        observar que alguns recursos do site podem não funcionar 
                        corretamente. Por exemplo, você pode não conseguir acessar 
                        determinadas áreas do site ou não receber informações 
                        personalizadas durante a visita. Se você utiliza diferentes 
                        dispositivos para acessar o site (como computador, 
                        smartphone, tablet, etc.), lembre-se de configurar as 
                        preferências de cookies em cada navegador de cada 
                        dispositivo de acordo com suas preferências.
                    </p>

                    <footer>
                        <img src={logo} alt="Logo Auati" title="Auati - seu trabalho, sua moeda." />
                        <h3>
                            Auati - seu trabalho, sua moeda.
                        </h3>
                    </footer>
                </article>
			</main>
		);
    }
}

export default Cookies;