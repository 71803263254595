import React from 'react';
// import { Link } from 'react-router-dom';

class Poster extends React.Component {
    
    render() {
        const ad = this.props.ad;
        const image = ad.poster ? ad.poster : "placeholder-ad.png";

        return (
            <figure className='poster'>
                <img 
                    src={"images/ad/"+image} 
                    alt={"Image for ad " +ad.title}
                    title={ad.title}
                    />
            </figure>
        )
    }
}

export default Poster;