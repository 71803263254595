import React from 'react';
import { withRouter } from "react-router-dom";
import GlobalContext from '../globalContext.js';
import Formlogin from '../components/formlogin.js';


class LoginPage extends React.Component {
	
	static contextType = GlobalContext;

    componentDidMount() {
		document.title = "Fazer login - Auati";
	}

	render() {
		
		return (
			<main className="login">
                <section>
                    <h1>
                        <span>Entrar no Auati</span>
                    </h1>
                    <p>
                        Entre e faça parte dessa rede colaborativa de 
                        profissionais que desejam fazer permuta de serviços.
                    </p>
                    <Formlogin 
                        action={this.props.action} 
                        email={this.props.email}
                        setEmail={this.props.setEmail} />
				</section>
			</main>
		);
	}
}

export default withRouter(LoginPage);