import React from "react";
import { useParams } from "react-router-dom";


function dumpValue(data) {
    const type = typeof data;
    if (data && (type === "object")) {
        return <React.Fragment>
            (object)<ul style={{ listStyle: "circle", paddingLeft: "10px", marginLeft: "10px"}}>
                {Object.entries(data)
                    .sort((a,b) => a[0] > b[0])
                    .map(([key, value]) => <li key={key}>
                        {key}: {dumpValue(value)}
                    </li>)
                }
            </ul>
        </React.Fragment>;
    } else {
        return `(${type}) "${data}"`;
    }
}

function LocalStorageMonitor() {
    const params = useParams();
    const [ data, setData ] = React.useState({});

    React.useEffect(function observeLocalStorage() {
        function readLocalStorage() {
            setData(JSON.parse(localStorage[params.localStorageKey] || "{}"));
        }
        const intervalHandler = setInterval(readLocalStorage, 250);
        return function cleanup() {
            clearInterval(intervalHandler);
        };
    }, [ params.localStorageKey ]);

    return <React.Fragment>
        {dumpValue(data)}
    </React.Fragment>;
}

export default LocalStorageMonitor;