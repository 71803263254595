import React from "react";

import About from '../components/about';
import Actions from '../components/actions';
import ButtonRequestAuati from '../components/ButtonRequestAuati';
import Galery from '../components/galery';
import Poster from '../components/poster';
import Ratings from '../components/ratings';
import Stars from '../components/stars';

/*
 * TODO: Código a ser incluído futuramente. São funcionalidades a serem
 * desenvolvidas, cujas referências foram movidas para esta função que não faz
 * nada para removê-las de dentro de blocos de comentários.
 */
function FutureInclusions({ ad }) {

    return false && <React.Fragment>
        <Ratings ad={ad}></Ratings>

        <div className='grades'>
            <h4>Distribuição das notas</h4>
            <ul className='bars'>
                {ad.bars.map((o, i) => (
                <li key={i}>
                    <div className={'bar '+o.stars+'-stars'}>
                        <div className={'rating-'+o.stars} style={{height: (o.amount / ad.totalStars * 100) +'%'}}>
                        </div>
                    </div>
                    <span>{o.amount}</span>
                </li>
                ))}
            </ul>
        </div>

        <div className='tabs reviews'>
            <div className='selector'>
                <button type='button' className='selected'>Todas {ad.ratings.length}</button>
                <button type='button'>Positivas {ad.ratings.length}</button>
                <button type='button'>Críticas {ad.ratings.length}</button>
            </div>
            <ul className='content'>
                {ad.ratings.map(o => (
                <li key={o.id} className='review'>
                    <Stars ad={ad} />
                    <button className='useful'>
                        <span>Foi útil?</span>
                    </button>
                    <h3>{o.report.title}</h3>
                    <p>{o.report.description}</p>
                </li>
                ))}
            </ul>
        </div>
    </React.Fragment>;
}

function TagList({ className, title, tagList, content }) {
    return <div className={ className }>
        <h4>{ title }</h4>
        <p>
            {tagList.map((tag, i) => <React.Fragment key={tag.id}>
                { (i > 0) && ", " }
                <span className='tag'>{tag.name}</span>
            </React.Fragment>)}
            {content}
        </p>
    </div>;
}

function AdView({ ad, onEditAdClick, onRemoveAdClick, loadAd }) {

    return ad && <article>

        <Poster ad={ad} />

        <div className='moreinfo'>
            <h4>Mais informações</h4>
            <p>{ad.moreinformation}</p>
            <Galery ad={ad} />
        </div>

        <FutureInclusions />

        <Actions ad={ad} />

        <section className='summary'>
            <h1>{ad.title}</h1>
            {ad.person.city && (<address>{ad.person.city} / {ad.person.state}</address>)}
            <TagList
                className="offer"  title="Ofereço"
                tagList={ad.offer_tags} content={ad.offer}/>
            <TagList
                className="want"  title="Aceito"
                tagList={ad.want_tags} content={ad.want}/>
            <ButtonRequestAuati
                ad={ad} loadAd={loadAd}
                onEditAdClick={onEditAdClick} onRemoveAdClick={onRemoveAdClick}/>
            <div className='professional'>
                <About person={ad.person} links={ad.links} />
            </div>
        </section>
    </article>;
}

export default AdView;