import React from 'react';
import { getUserData, setUserData } from '../utils/JWTAuth.js';
import { setUser } from '../utils/service.js';
import GlobalContext from '../globalContext.js';
import Avatar from '../components/avatar.js';
import FormPhoto from "../components/formphoto.js";
import { Switch, Case } from "../components/switch";
import Record from "../components/fields/Record";
import { formatDate } from '../utils/convert.js';
import { Console, Document } from '../utils/reactUtils';
import { If } from '../components/fields/If';
import { FieldControl } from '../components/fields/Record';
import profileFieldDefs from '../fieldDefs/profile';
import controlTemplates from '../components/controlTemplates';

function getMergedUserData() {
    const userData = getUserData();
    const person = userData.person;
    const cc = person && person.countrycode;
    return { ...userData, ...person, nobrasil: (cc === undefined || cc === null) ? cc : +(cc === "BR")};
}

function ViewPhoto({ changeModeTo }) {

    const record = Record.use();
    const [ magnified, setMagnified ] = React.useState("");

    function magnifyPhoto() {
        if (!magnified) setMagnified(" magnified");
        else {
            const visualizar = changeModeTo("visualizar");
            visualizar();
        }
    }

    return <div className={'seephoto' + magnified}>
        <img alt='' onClick={magnifyPhoto} src={'/images/person/' + record.getFieldValue("avatar")} />
    </div>;
}

function AvatarField() {

    const globalContext = React.useContext(GlobalContext);
    const record = Record.use();
    const data = record.getData();

    function applyPhoto(data) {
		const userdata = { ...getUserData(), ...data, person: data };
		setUserData(userdata);
		globalContext.setUserData(userdata);
        record.setData(userdata);
    }

    async function changePhoto() {
        globalContext.openDialog({
			name: 'photo',
			title: 'Selecionar foto',
			modal: false,
			content: <FormPhoto
				action={applyPhoto}
				close={globalContext.closeDialog}
				loading={globalContext.loading}
				setLoading={globalContext.setLoading}
				person={data}
			/>
		});
    }

    return <Avatar person={data} action={changePhoto} edit={true} />;
}

function ViewProfile({ changeModeTo }) {

    return <Record defaultValue={getMergedUserData()}
        fieldDefs={profileFieldDefs}
        templates={{
            "default": ({ label, fieldValue }) => <React.Fragment>
                <dt>{ label }</dt>
                <dd>{fieldValue || <span className='error'>Não informado</span>}</dd>
            </React.Fragment>,

            "date": ({ label, fieldValue }) =>
                <small>{label} {formatDate(fieldValue)}.</small>,
        }}
    >
        <dl>
            <AvatarField />
            <FieldControl fieldName="nickname"/>
            <FieldControl fieldName="fullname" />
            <FieldControl fieldName="tel" />
            <FieldControl fieldName="email" />
            <FieldControl fieldName="about_me" />
            <FieldControl fieldName="doc" />
            <p>
                <FieldControl fieldName="date_created" label="Possui conta no Auati desde" templateName="date"/>
                <br />
                <FieldControl fieldName="date_updated" label="Última atualização feita em" templateName="date"/>
            </p>
        </dl>
        <div className='buttons'>
            <button type='button' onClick={changeModeTo("editar")}>
                Editar meus dados
            </button>
        </div>
    </Record>;
}

function ChangePassword() {

    const record = Record.use();
    const { changePassword, password, password2 } = record.getData();
    const [ showPassword, setShowPassword ] = React.useState();

    if (!changePassword) return "";

    const min = /^.{8,}$/.test(password);
    const upp = /(?=.*[A-Z])/.test(password);
    const low = /(?=.*[a-z])/.test(password);
    const num = /(?=.*[0-9])/.test(password);
    const passIsValid = (min && upp && low && num);
    const same = (password === password2);
    const incomplete = (passIsValid && !same);
    const valid = (passIsValid && same);
    const notValid = (!passIsValid && (password.length === 0 || !same));

    function Fmt({ valid, children }) {
        return (valid || !password.length) ? children :
            <b style={{color:'var(--color-error)'}}>
                {children}
            </b>;
    }

    return <React.Fragment>
        <FieldControl fieldName="password" showPassword={showPassword}/>
        <FieldControl fieldName="password2"
            type="password" showPassword={showPassword}
            label="Confirmação de senha"
            title="A confirmação da senha deve ser igual à senha informada."
        />
        <button type='button' className={'togglepass '+ (showPassword ? "text" : "password")} onClick={()=>setShowPassword(!showPassword)}></button>
        <small>
            <If test={valid}>
                <span style={{color:'var(--color-success)'}} >
                    Esta é uma boa senha!
                </span>
            </If>
            <If test={incomplete}>
                <span style={{color:'var(--color-error)'}} >
                    Confirme a senha para ajudar a memorizá-la.
                </span>
            </If>
            <If test={notValid}>
                <span>
                    A senha deve ter
                    <Fmt valid={min}> no mínimo 8 caracteres</Fmt>,
                    <Fmt valid={upp}> letras maiúsculas</Fmt>,
                    <Fmt valid={low}> minúsculas</Fmt> e
                    <Fmt valid={num}> números</Fmt>.
                </span>
            </If>
        </small>
    </React.Fragment>;
}

function EditProfile({ changeModeTo }) {

    const loading = false;

    const globalContext = React.useContext(GlobalContext);

    async function saveUserData({ data, formData }) {
        formData.append("id", data.id);
		const result = await setUser(formData, data.id);
		if (!result.success) {
            globalContext.setMsg("Ocorreram erros ao salvar seus dados.", "error");
			return console.error("saveUserData error: ", result.data || 'Não foi possível salvar seus dados.');
		}
        console.log("saveUserData: ", result);
		setUserData(result.data);
		if (!result.success) {
			console.error("saveUserData error: ", result.data || 'Não foi possível salvar seus dados.');
		}
		const visualizar = changeModeTo("visualizar");
		visualizar();
    }

    return <Record.Form autoComplete='off'
            defaultValue={getMergedUserData()}
            localStorageKey="myprofile"
            onSubmit={saveUserData}
            beforeUnloadWindow={ record => { }}
            fieldDefs={profileFieldDefs}
            templates={controlTemplates}
    >
        <fieldset>
            <legend>
                Mantenha seus dados atualizados.
            </legend>
            <div className='fields'>
                <label>Foto</label>
                <AvatarField />
                <FieldControl fieldName="nickname" />
                <FieldControl fieldName="fullname" />
                <FieldControl fieldName="email" />
                <FieldControl fieldName="doc" />
                <FieldControl fieldName="tel" />
                <FieldControl fieldName="about_me" />
                <FieldControl fieldName="nobrasil" />
                <If fieldName='nobrasil' test={1}>
                    <FieldControl fieldName="state" />
                    <FieldControl fieldName="city" />
                </If>
                <If fieldName='nobrasil' test={0}>
                    <FieldControl fieldName="countrycode" />
                    <FieldControl fieldName="city" controlType="text"  placeholder=""/>
                </If>
                <FieldControl
                    fieldName='changePassword'
                    label='Quero alterar minha senha' type="check"
                />
                <ChangePassword />
            </div>

        </fieldset>

        <footer>
            <div className='buttons'>
                <button type='submit' disabled={loading}>
                    <span>Salvar meus dados</span>
                </button>
                <button type="button" onClick={changeModeTo("visualizar")}>Cancelar</button>
            </div>
        </footer>
    </Record.Form>;
}

function MyProfile({ modeChanger, mode }) {

    // {this.state.msg && <Msg text={this.state.msg} type={this.state.msgType} onClose={this.hideError} />}
    return <main className='myaccount'>
        <Document title="Meus dados de usuário"/>
        <header>
            <h2>Meus dados</h2>
        </header>
        <Console activeComponentId={ mode || 'visualizar' }/>
        <Switch value={ mode || 'visualizar' }>
            <Case when="foto">
                <ViewPhoto changeModeTo={modeChanger}>Foto</ViewPhoto>
            </Case>
            <Case when="editar">
                <EditProfile changeModeTo={modeChanger}>Editar</EditProfile>
            </Case>
            <Case when="visualizar">
                <ViewProfile changeModeTo={modeChanger}>Visualizar</ViewProfile>
            </Case>
        </Switch>
    </main>;
}

export default MyProfile;
