import React from "react";
import Avatar from "../components/avatar.js";
import { patchAvatar } from "../utils/service.js";
import { Switch, Case } from "../components/switch";
import If from "../components/fields/If";
import { useEffect } from "../utils/reactUtils";

function handleSubmit(event) {
    event.preventDefault();
    return false;
}

function FormPhoto({ person, action, close }) {

    const [ state, doSetState ] = React.useState({ mode: "form" });
    const { mode, captured, capturing, file, uploadMessageError } = state;

    const videoRef = React.useRef();
    const canvasRef = React.useRef();

    const hasPhoto = !!person.avatar;
    const hasCamera = !!navigator.mediaDevices;

    const setState = React.useCallback(function setState(newState) {
        return doSetState({ ...state, ...newState });
    }, [ state ]);

    function changeModeTo(mode) {
        return () => setState({ mode, captured: false });
    }

    useEffect(function startCapturingWhenInCameraMode(ifMounted, onCleanup) {
        if (mode === "camera") {
            if ((mode === "camera") && navigator.mediaDevices) {
                navigator.mediaDevices.getUserMedia({
                    video: { width: 720, height: 720 },
                    audio: false
                })
                .then(ifMounted(stream => {
                    videoRef.current.srcObject = stream;
                    onCleanup(() => stream.getTracks()
                        .forEach(track => track.stop()));
                }));
            }
        }
    }, [ mode ]);

    useEffect(function saveWhenRequested(ifMounted) {

        if ((mode === "saving") && file) {
            const id = person.id_user;
            const formData = new FormData();
            formData.append("avatar", file);
            patchAvatar(formData, id)
                .then(ifMounted(result => {
                    if (result.success) {
                        action(result.data);
                        close();
                    } else {
                        alert(uploadMessageError);
                        setState({ mode: "form" });
                    }
                }));
        }
    }, [
        mode, file, person.id_user, action,
        close, uploadMessageError, setState
    ]);

    useEffect(function captureFromCameraWhenRequested(ifMounted) {
        if (capturing) {
            const id = person.id_user;
            const video = videoRef.current;
            const canvas = canvasRef.current;
            const canvasContext = canvas.getContext('2d');
            canvasContext.drawImage(video, 0, 0, canvas.width, canvas.height);
            canvas.toBlob(ifMounted(blob =>
                setState({
                    capturing: false, captured: true,
                    file: new File(
                        [ blob ], 'avatar'+id+'.jpg', { type: 'image/jpeg' }
                    )
                })
            ), 'image/jpeg');
        }
    }, [ capturing, person.id_user, setState ]);


    function sendFile(event) {

        const input = event.target;

        if (input.files.length === 0) {
            return;
		}

		let file = input.files[0];
		let allowed_mime_types = [ 'image/jpeg', 'image/png' ];
		let allowed_size_mb = 5;

		if (allowed_mime_types.indexOf(file.type) === -1) {
            alert('Escolha uma imagem no formato JPEG ou PNG.');
			return;
		}

		if (file.size > allowed_size_mb*1024*1024) {
            alert('Arquivo muito grande. \nEscolha um arquivo de até '+allowed_size_mb+'MB.');
			return;
		}

        setState({
            mode: "saving", file,
            uploadMessageError: "Ocorreram erros ao enviar o arquivo da foto."
        });
    }

    function removePhoto() {
        setState({
            mode: "saving",
            file: "none",
            uploadMessageError: "Ocorreram erros ao remover a foto"
        });
    }

    return <form onSubmit={handleSubmit}>
        <Switch value={mode}>
            <Case when="form">
                <Avatar componentmode="form" person={person} />
            </Case>
            <Case when="camera">
                <figure className='avatar'>
                    <video style={captured ? {display: 'none'} : {}} ref={videoRef} width="1024" height="1024" autoPlay></video>
                    <canvas style={captured ? {} : {display: 'none'}} ref={canvasRef} width="1024" height="1024" />
                </figure>
            </Case>
        </Switch>
        <If test={mode === "form" || mode === "camera"}>
            <p>Selecione uma opção:</p>
        </If>
        <Switch value={mode}>
            <Case when="form">
                <div componentmode="form" className='fields'>
                    <If test={hasCamera}>
                        <button type="submit" onClick={changeModeTo("camera")}>Usar a câmera</button>
                    </If>
                    <label className="button" htmlFor="inputFile" >Enviar um arquivo</label>
                    <input type="file" id="inputFile" accept="image/jpeg, image/png" onChange={sendFile} style={{display: 'none'}} />
                    <If test={hasPhoto}>
                        <button type="button" onClick={removePhoto}>Remover foto atual</button>
                    </If>
                </div>
            </Case>
            <Case when="camera">
                <div className='fields'>
                    <If test={captured}>
                        <button type="submit" onClick={changeModeTo("saving")}>Usar esta foto</button>
                        <button type="button" onClick={() => setState({captured: false})}>Tirar outra</button>
                    </If>
                    <If test={!captured}>
                        <button type="submit" onClick={() => setState({capturing: true})}>Capturar foto</button>
                    </If>
                    <button type="button" onClick={changeModeTo("form")}>Cancelar</button>
                </div>
            </Case>
            <Case when="saving">
                <div className='fields'>
                    <small>Enviando...</small>
                </div>
            </Case>
        </Switch>
    </form>;
}

export default FormPhoto;
