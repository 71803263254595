import React, { Component } from 'react';
import './App.css';
import { 
    isLogged, 
    getUserData, 
    logout 
} from "./utils/JWTAuth.js";
import {
    BrowserRouter as Router,
    Redirect
} from 'react-router-dom';

import Dialog from './components/dialog';
import Formlogin from './components/formlogin';
import Formforget from './components/formforget';
import FormSignup from './components/formsignup';
import Top from "./components/top";
import { SystemMessageView } from "./components/msg.js";
import CookiePolicy from "./components/cookiePolicy";
import ContextProviders from './components/contextProviders';
import Routes from './routes';

class App extends Component {

    constructor(props) {
        super (props);
        
        this.setLoading = this.setLoading.bind(this);
        this.setUploadProgress = this.setUploadProgress.bind(this);
        this.setAccepted = this.setAccepted.bind(this);
        this.setFilterStatus = this.setFilterStatus.bind(this);
        this.setLoginStatus = this.setLoginStatus.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.doLogout = this.doLogout.bind(this);
        this.doLogin = this.doLogin.bind(this);
        this.doForget = this.doForget.bind(this);
        this.doSignup = this.doSignup.bind(this);        
        this.setEmail = this.setEmail.bind(this);
        this.getEmail = this.getEmail.bind(this);        
        this.setNewUser = this.setNewUser.bind(this);
        this.setUserData = this.setUserData.bind(this);

        this.state = {
            logged: isLogged(),
            userData: getUserData(),
            email: '',
            dialogData: null,
            loading: false,
            accepted: false,
            redirect: false,
            redirectPath: '',
            filterStatus: '',
            uploadProgress: 0,
            msg: {
                text: '',
                type: ''
            },
            newUser: null,
        };
    }

    setUserData(data) {
        this.setState({
            userData: data
        })
    }
    
    doLogout() {
        this.setState({ 
            logged: !logout(),
            redirect: true,
            redirectPath: "/"
        });
    }

    doLogin(result) {
        let success = result && result.success;
        let path = success ? '/' : '/';
        if (success) {
            this.setState({ 
                logged: success,
                redirect: true,
                redirectPath: path,
                userData: success ? getUserData() : ''
            });
        }
    }

    setNewUser(data) {
        this.setState({newUser: data})
    }
    
    doForget(result) {
        let success = result && result.success;
        this.setState({ loginDialog: success })
    }

    doSignup(result) {
        let success = result && result.success;
        this.setState({ loginDialog: success })
    }

    setLoading(isLoading) {
        this.setState({ loading: isLoading });
    }
    
    setUploadProgress(value) {
        this.setState({ uploadProgress: value });
    }

    setEmail(email) {
        this.setState({ email: email });
    }

    getEmail() {
        return this.state.email;
    }

    setLoginStatus(value) {
        this.setState({ 
            redirect: true,
        });
    }

    setDataDialog(type) {
        switch (type) {
            case 'login':
                return {
                    name: 'login',
                    title: 'Acesse a sua conta',
                    modal: false,
                    content: <Formlogin 
                        action={this.doLogin} 
                        close={this.closeDialog} 
                        email={this.state.email}
                        setEmail={this.setEmail}
                        loading={this.state.loading}
                        setLoading={this.setLoading} 
                    />
                }
            
            case 'forget':
                return {
                    name: 'forget',
                    title: 'Recuperar senha',
                    modal: false,
                    content: <Formforget 
                        action={this.doForget} 
                        close={this.closeDialog} 
                        getEmail={this.getEmail}
                        setEmail={this.setEmail}
                        loading={this.state.loading}
                        setLoading={this.setLoading} 
                    />
                }
                
            case 'signup':
                return {
                    name: 'signup',
                    title: 'Criar conta',
                    modal: false,
                    content: <FormSignup 
                        action={this.doSignup} 
                        close={this.closeDialog} 
                        getEmail={this.getEmail}
                        setEmail={this.setEmail}
                        loading={this.state.loading}
                        setLoading={this.setLoading} 
                    />
                }
            default: return null;
        }
    }

    openDialog(data) {
        if (typeof data === 'string') {
            data = this.setDataDialog(data);
        }
        if (data) {
            this.setState({
                dialogData: data,
            });
        }
    }
    
    closeDialog() {
        this.setState({dialogData: null})
    }

    setFilterStatus(value) {
        if (value === this.state.filterStatus) {
            value = {};
        }
        this.setState({ filterStatus: value});
    }

    setAccepted(value) {
        this.setState({ accepted: value });
    }
      
    renderRedirect() {
        let path = this.state.logged ? '/' : '/';
        if (this.state.redirect) {
            return <Redirect to={path} />
        }
    }

    renderRedirect2() {
        let path = this.state.redirectPath;
        if (this.state.redirect) {
            return <Redirect to={path} />
        }
    }

    bodyClasses(toApply, className) {
        if (toApply) {
            document.body.classList.add(className);
        } else {
            document.body.classList.remove(className);
        }
    }

    render() {
        this.bodyClasses(this.state.logged, 'logged');
        return <Router basename={process.env.PUBLIC_URL}>
            <ContextProviders
                searchPath="/s/:search"
                global={{
                    loginDialog: this.openLoginDialog,
                    openDialog: this.openDialog,
                    closeDialog: this.closeDialog,
                    newUser: this.state.newUser,
                    setNewUser: this.setNewUser,
                    setUserData: this.setUserData,
                    logged: this.state.logged,
                    setLoading: this.setLoading,
                    loading: this.loading
                }}
            >
                <Top
                Router={Router}
                logged={this.state.logged} 
                setLoginStatus={this.setLoginStatus} 
                filterStatus={this.state.filterStatus}
                userData={this.state.userData}
                setFilterStatus={this.setFilterStatus}
                doLogout={this.doLogout}
                openLoginDialog={this.openLoginDialog}
                />
                
                {this.renderRedirect()}

                {this.renderRedirect2()}
                
                <Routes that={this} />

				{/* <Footer /> */}


                {this.state.dialogData &&
                    <Dialog 
                    data={this.state.dialogData} 
                    onClose={this.closeDialog} 
                    /> 
                }

                {this.state.shareDialog && 
                    <Dialog 
                    data={this.shareDialogData} 
                    onClose={this.closeShareDialog} 
                    /> 
                }
            {this.state.loading && (
                <div className="loading">
                {this.state.uploadProgress > 0 && this.state.uploadProgress < 100 && (
                    <div className="upload-progress">
                        <progress value={this.state.uploadProgress} max="100"></progress>
                        <p className="porcent">
                            <span className="raise" style={{height: (100 - this.state.uploadProgress)+'%'}}></span>
                            {this.state.uploadProgress}%
                        </p>
                    </div>
                )}
                {(this.state.uploadProgress === 0 || this.state.uploadProgress === 100) && (
                    <p className="waiting">Aguarde enquanto estamos processando...</p>
                    )}
            </div>
            )}
            <SystemMessageView />
            <CookiePolicy />
        </ContextProviders>
    </Router>;
  }
}

export default App;
