import React from 'react';
import { withRouter } from "react-router-dom";
import { isLogged, getUserData } from "../utils/JWTAuth.js";
import { TabControl, Tab } from '../components/tabs.js';
import MyAds from './myads/myads.js';
import MyFavorites from './myfavorites.js';
import Myprofile from './myprofile';


class Myaccount extends React.Component {
	constructor (props) {
		super(props);
		this.timerId = null;

        [ // Funções que precisam estar acopladas à instância atual
            "tabActionChanger",
            "setTabAction",
            "setActiveTabId"
        ].forEach(name => this[name] = this[name].bind(this));

		this.state = {
			data: getUserData()
		};

	    this.props.setLoading(true);
	}

	async componentDidMount() {
		document.title = "Minha conta - Auati";
		if (!isLogged()) {
            localStorage.setItem('destination', this.props.history.location.pathname);
			this.props.history.push('/login');
		}
		this.props.setLoading(false);
	}

	naoInformado(data) {
		return data || <span className='error'>Não informado</span>;
	}

    getActionPath(tabAction) {
        const { match: { path, params: { activeTabId } } } = this.props;
        return path.replace(/:activeTabId\??/, activeTabId || "perfil")
                   .replace(/:tabAction\??/, tabAction);
    }

    getTabAction() {
        return this.props.match.params.tabAction;
    }

    setTabAction(action) {
        this.props.history.push(this.getActionPath(action));
    }

    /*
     * Retorna uma função que ao ser acionada altera a action da aba ativa para
     * o id informado.
     */
    tabActionChanger(action) {
        return () => this.setTabAction(action);
    }

    /*
     * Retorna uma função que ao ser acionada altera a aba ativa para o id
     * informado.
     */
    setActiveTabId(tabId) {
        const {
            match: { path, params: { activeTabId } }, history
        } = this.props;
        if ( activeTabId !== tabId ) {
            history.push(path.replace(/:activeTab.*$/, tabId));
        }
    }

	render() {

		return this.state.data.person ? (
			<main className="myaccount">
				<h1>
					<span>Minha conta</span>
				</h1>
				<section>
					<h3>Olá, {this.state.data.person.nickname}!</h3>
					<TabControl
                        activeTabId={this.props.match.params.activeTabId || "perfil"}
                        setActiveTabId={this.setActiveTabId}>
                        <Tab id="perfil" label="Perfil" hint="Dados do perfil">
                            <Myprofile
                                logged={this.props.logged}
                                setLoginStatus={this.props.setLoginStatus}
                                loading={this.props.loading}
                                setLoading={this.props.setLoading}
                                mode={this.getTabAction()}
                                modeChanger={this.tabActionChanger}/>
                        </Tab>
                        <Tab id="anuncios" label="Anúncios">
                            <MyAds
                                mode={this.getTabAction() || "listar"}
                                setMode={this.setTabAction}/>
                        </Tab>
                        <Tab id="favoritos" label="Favoritos">
                            <MyFavorites />
                        </Tab>
                    </TabControl>
				</section>
			</main>
		) : '';
	}
}

export default withRouter(Myaccount);