import React from 'react';

function Console(props) {
    if ( process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        React.useEffect(() => {
            Object.entries(props).forEach(([key, value]) => {
                if (typeof value === "function") value = value();
                console.log(key, "=>", value);
            });
        }, [ props ]);
    }
    return "";
}

function useWindowEventListener(eventName, callback) {
    React.useEffect(function addListener() {
        if (typeof callback === "function") {
            window.addEventListener(eventName, callback);
            return ()=> window.removeEventListener(eventName, callback);
        }
    }, [ eventName, callback ]);
    return callback;
}

const DocumentContext = React.createContext(function createContext() {

    const contextList = [];
    let updateTitle = scheduleUpdateTitle;

    function doNothing() { }

    function doUpdateTitle() {
        const context = contextList.findLast(c => c.title);
        if (context) document.title = context.title;
        updateTitle = scheduleUpdateTitle;
    }

    function scheduleUpdateTitle() {
        setTimeout(doUpdateTitle, 1);
        updateTitle = doNothing;
    }

    function newContext() {
        const context = { newContext };
        context.setTitle = function setTitle(title) {
            context.title = title;
            updateTitle();
        };
        context.remove = function remove() {
            const index = contextList.findLastIndex(c => c === context);
            if (index >= 0) {
                contextList.splice(index, 1);
                updateTitle();
            }
        };
        contextList.push(context);
        return context;
    }

    return { newContext };
} ());

function Document({ title, children, onBeforeUnload, beforeUnloadMessage }) {

    if (beforeUnloadMessage && !onBeforeUnload) {
        onBeforeUnload = () => beforeUnloadMessage;
    }
    useWindowEventListener("beforeunload", onBeforeUnload && function handle(event) {
        const message = onBeforeUnload(event);
        if (message) {
            event.preventDefault();
            event.returnValue = message; // Gecko, Trident, Chrome 34+
        }
		return message; // Gecko, WebKit, Chrome <34
    });

    const context = React.useContext(DocumentContext);
    const me = React.useMemo(() => context.newContext(), [ context ]);
    me.setTitle(title);
    React.useEffect(function cleanup() {
        return () => me.remove();
    }, [ me ]);
    return <DocumentContext.Provider value={me}>
        {children}
    </DocumentContext.Provider>;
}

function useEffect(theEffect, deps) {
    return React.useEffect(() => {
        let mounted = true;
        let cleanupList = [];
        let cleanup = theEffect(
            function ifMounted(mountedCallback) {
                return function doCallbackIfMounted() {
                    if (mounted) {
                        return mountedCallback.apply(this, arguments);
                    }
                };
            },
            function onCleanup(cleanupCallback) {
                cleanupList.push(cleanupCallback);
            }
        );
        if (cleanup) cleanupList.push(cleanup);
        return () => {
            mounted = false;
            cleanupList.forEach(f => (typeof f === "function") && f());
        };
    }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}

function resolve(promisor) {
    return (typeof promisor === "function") ?
        new Promise(resolve => resolve(promisor())) : Promise.resolve(promisor);
}

function usePromise(promisor, initialResult, onError) {
    const [ result, setResult ] = React.useState(initialResult);
    React.useEffect(() => {
        if (typeof onError === "function") {
            resolve(promisor).catch(onError).then(setResult);
        } else {
            resolve(promisor).then(setResult);
        }
    }, [ promisor ]);
    return [ result ];
}

export { useEffect, usePromise, useWindowEventListener, Console, Document };
