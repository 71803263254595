import React from "react";

export default {
    "default": props => {
        const {
            type, fieldName, label, controlType,
            required, renderControl, instructions
        } = props;

        const controlClass = controlType || type;
        const beforeLabel = controlClass === "check";
        const afterInstructions = controlClass === "radio";
        const middle = !afterInstructions && !beforeLabel;

        return <div className={`field ${controlClass} input${controlClass} ${fieldName}`}>
            {beforeLabel && renderControl(props)}
            {label && <label htmlFor={fieldName}>
                {required && <span className="required" title="Preenchimento obrigatório"/>}
                {label}
            </label>}
            {middle && renderControl(props)}
            <p>{instructions}</p>
            {afterInstructions && renderControl(props)}
        </div>;
    }
};