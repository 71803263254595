import React from 'react';
import { withRouter } from "react-router-dom";
import { getFavorites } from "../utils/service.js";
import Card from "../components/card.js";

class MyFavorites extends React.Component {


    constructor(props) {

        super(props);

        this.state = {
            ads: [],
            loading: true
        };
    }

    async componentDidMount() {
        let result = await getFavorites();
        this.setState({
            ads: (result && result.success) ? result.data : [],
            loading: false
        });
    }

    render() {
        return  (
			<main className="myfavorites">
                <section>
                    <h1>Favoritos</h1>
                    {
                        (this.state.loading &&
                            <h2>Carregando...</h2> ) ||
                        (this.state.ads.length &&
                            <ul className="ads">{
                                this.state.ads.map(ad => (
                                   <Card ad={ad} key={ad.id} /> )
                                )
                            }</ul>) ||
                        <h2>Você não possui favoritos</h2>
                    }
                </section>
			</main>
		);
    }
}

export default withRouter(MyFavorites);