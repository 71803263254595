import React from 'react';
import GlobalContext from '../globalContext.js';
import loadingGif from '../img/loading.gif';
import { recover } from "../utils/JWTAuth.js";


class formForget extends React.Component {

	constructor(props) {
		super (props);	
		this.forget = this.forget.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.backToLogin = this.backToLogin.bind(this);
		this.backToForm = this.backToForm.bind(this);
		this.getTokenAndSendData = this.getTokenAndSendData.bind(this);

		this.state = {
			email: this.props.getEmail(),
			situation: 'form', // form | success | error
			msgError: '',
			loading: false
		};
	}

	static contextType = GlobalContext;

	handleInputChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		this.setState({
			[name]: value
		});
	}

	backToForm() {
		this.setState({situation: 'form'});
	}

	backToLogin() {
		this.props.close();
		this.context.openDialog("login");
	}
	
	async getTokenAndSendData(formData) {
		this.setState({ loading: true })
		// context.props.setLoading(true);
		//this.props.setLoading(true);
		await window.grecaptcha.ready(async () => {
			const token = await Promise.resolve(window.grecaptcha.execute('6LciP68ZAAAAAPYLrM0yiYxTwt-QtDjUF7Gxxbry', {action: 'forgetPassword'}));
			formData["grecaptcha_token"] = token;
			let msg = '';
			const result = await recover(formData);
			if (result && result.success) {
				this.setState({ situation: 'success' });
			} else {
				msg = result && result.data;
				this.setState({ msgError: msg, situation: 'error' });
				this.context.showError(msg || 'Desculpe, mas estamos com algumas dificuldades para recuperar a senha.');
				if (typeof msg === 'object') {
					msg = JSON.stringify(msg);
				}
				this.context.showError(msg || 'Estamos com algumas dificuldades. Não conseguimos recuperar a senha.');
			}
			this.props.action(result);
			// context.props.setLoading(false);
			this.setState({ loading: false })
		});
	}
		
	async forget(event) {
		event.preventDefault();
	    let info = {
			email: this.state.email
		};
		await this.getTokenAndSendData(info, this);	
	}

	render() {
		switch (this.state.situation) {
			case 'success': return (
				<section>
					<p className="success">
						<span>
							Uma mensagem foi enviada para o e-mail 
							<b> {this.state.email} </b> com um link para recuperar
							o acesso à sua conta no Auati.
						</span>
					</p>
					<footer>
						<div className="buttons">
							<button type="button" className='link' onClick={this.props.close}>
								<span>Fechar</span>
							</button>
						</div>
					</footer>
				</section>
			);
			case 'error': return (
				<section>
					<p>
						Ocorreram erros e não foi possível recuperar a senha.
					</p>
					<p className="error">
						<span>
							{this.state.msgError}
						</span>
					</p>
					<footer>
						<div className="buttons">
							<button type="button" className='link' onClick={this.backToForm}>
								<span>Voltar</span>
							</button>
						</div>
					</footer>
				</section>
			);
			default: return (<>
				{this.state.loading && (<div className='waiting'>
					<img src={loadingGif} alt="" />
					<span>Aguarde...</span>
				</div>)}

				{!this.state.loading && (<>
				<form onSubmit={this.forget}>
					<fieldset>
						<p>
							Informe seu e-mail para receber um link de alteração de senha.
						</p>
						<div className='fields'>
							<div className="field email">
								<label htmlFor="email">E-mail:{this.props.email} </label>
								<input 
									name='email' 
									id='email' 
									type='email'
									required
									autoComplete="username"
									placeholder="Insira seu e-mail"
									value={this.state.email} 
									onChange={this.handleInputChange} 
								/>
							</div>
						</div>
					</fieldset>
					<footer>
						<div className="buttons">
							<button type="submit" disabled={this.state.loading}>
								<span>Mande-me um e-mail com o link</span>
							</button>
							<button type="button" className="link" onClick={this.backToLogin}>
								<span>Fazer login</span>
							</button>
						</div>
					</footer>
				</form>
				</>)}
			</>);
		}
	}
}

export default formForget;