import React from 'react'
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import AdList from "../components/adlist"

const SearchContext = React.createContext();

function SearchContextProvider({ path, children }) {

    const location = useLocation();
    const match = useRouteMatch(path);
    const history = useHistory({});
    const matched = match && (match.url === location.pathname);
    const search = {
        term: (matched && match.params.search) || "",
        setTerm: term => history.push(path.replace(/:search/, term))
    };

    return <SearchContext.Provider value={search}>
        {children}
    </SearchContext.Provider>;
}

function SearchField() {

    const [ placeholder, setPlaceholder ] = React.useState("");
    const search = React.useContext(SearchContext);
    const input = React.createRef();

	function setSearchTerm(event) {
		event.preventDefault();
		search.setTerm(event.target.searchTerm.value);
	}

    React.useEffect(() => {
        function onResize() {
            const wsize = document.body.clientWidth;
            setPlaceholder(((wsize < 660 && wsize > 556) || wsize < 395) ?
                "Buscar por..." :
                "O que você está procurando?"
            );
        }
        setTimeout(onResize, 1);
        window.addEventListener("resize", onResize);
        return ()=> window.removeEventListener("resize", onResize);
    }, []);

    React.useEffect(() => {
        if (input.current) {
            input.current.value = search.term;
        }
    }, [ search.term, input ]);

    return <form className="search" onSubmit={setSearchTerm}>
        <input
            ref={input}
            type="search"
            required
            name="searchTerm"
            defaultValue={search.term}
            placeholder={placeholder}
        />
    </form>;
}

function Search() {

    const search = React.useContext(SearchContext);

    return (
        <main className='searchresults'>
            <header>
                <h1>
                    <span>Resultado da busca por <b>{search.term}</b></span>
                </h1>
            </header>
            <section className="adcover">
                <AdList search={search.term} />
            </section>
        </main>
    );
}

export default Search;
export { Search, SearchField, SearchContextProvider };
