import React from "react";
import Record from "./Record";

const IfContext = React.createContext();

function error(message) {
    throw new Error(message);
}

function useIfResult(show, children) {
    const elementName = show ? "Then" : "Else";
    const context = React.useContext(IfContext);
    if (context === undefined) {
        error(elementName + " must be a child of an If");
    }
    return (context === show) && <IfContext.Provider value={undefined}>
        { children }
    </IfContext.Provider>;
}

function Then({ children }) {
    return useIfResult(true, children);
}

function Else({ children }) {
    return useIfResult(false, children);
}

function If({ fieldName, test, children }) {
    const record = Record.use();
    let testResult;

    const transformed = React.useMemo(function buildElementsToRender() {
        // Testa apenas filhos que serão renderizados pelo React
        const childrenArray = Array(children).flat()
            .filter(e => e || (e === 0));
        let hasThen = false;
        let hasElse = false;
        childrenArray.forEach(function checkChildren(child, index) {
            if (child.type === Then) {
                if (hasThen) {
                    error("There can be only one Then element inside If");
                } else if (index > 0) {
                    error("Then element, when present, must be the first child of If");
                } else {
                    hasThen = true;
                }
            } else if (child.type === Else) {
                if (!hasThen) {
                    error("Else elements cannot be used without a Then element preceding them");
                } else if (hasElse) {
                    error("There can be only one Else element inside If");
                } else if (index > 1) {
                    error("Else element, when present, must be the second child of If");
                } else {
                    hasElse = true;
                }
            } else if (hasThen) {
                error("When a Then child is present, no other elements besides a single Else can be present");
            }
        });
        return hasThen ? children : <Then>{children}</Then>;
    }, [ children ]);

    if (fieldName) {
        const fieldValue = record.getFieldValue(fieldName);
        switch (typeof test) {
            case "function":
                testResult = test(fieldValue, fieldName);
                break;
            case "undefined":
                testResult = fieldValue;
                break;
            default:
                // eslint-disable-next-line eqeqeq
                testResult = test == fieldValue;
        }
    } else {
        switch (typeof test) {
            case "function":
                testResult = test(record);
                break;
            default:
                testResult = test;
        }
    }

    return <IfContext.Provider value={Boolean(testResult)}>
        {transformed}
    </IfContext.Provider>;
}

export default If;
export { If, Then, Else };
