import React from 'react';
import { getAds } from "../utils/service.js";
import { useEffect } from "../utils/reactUtils";
import Card from './card.js';
import { If, Then, Else } from '../components/fields/If';
import { Switch, Case, Otherwise } from '../components/switch';

function AdGroup({ ads, status, title }) {
    if (status) {
        ads = ads.filter(ad => ad.status === status);
    }
    return <If test={ads && ads.length}>
    { title && <h2>{ title }</h2> }
        <ul className="ads">
            { ads.map(ad => <Card ad={ad} key={ad.id} /> ) }
        </ul>
    </If>;
}

function AdList(props) {

    const { grouping, emptyResult, afterNonEmptyList } = props;
    const paramNames = [
        "category",
        "exclude",
        "search",
        "username",
        "my",
        "pageSize"
    ];
    const params =
        Object.fromEntries(paramNames.map(name => [ name, props[name] ]));
    const [ state, setState ] = React.useState({
        ads: [], page: 1, loading: true, params
    });
    const statePageSize = state.params.pageSize || 50;

    function nextPage() {
        setState({ ...state, loading: true, page: state.page + 1 });
    }

    const shouldReload = !state.loading &&
            paramNames.some(name => params[name] !== state.params[name]);
    if (shouldReload) {
        setState({ params, loading: true, ads: [], page: 1 });
    }

    useEffect(function loadPageWhenRequested(ifMounted) {
        if (state.loading) {
            const { pageSize, ...params } = state.params;
            getAds({ ...params, p: state.page, q: statePageSize })
                .then(ifMounted(function processResult(result) {
                    if (result && result.success) {
                        setState({
                            ...state,
                            ads: [ ...state.ads, ...result.data ],
                            count: result.metadata.count,
                            loading: false
                        });
                    } else {
                        setState({ ...state, loading: false });
                    }
                }));
        }
    }, [ state, statePageSize ]);

    return <Switch>
        <Case when={state.loading && !state.ads.length}>
            <div>Carregando...</div>
        </Case>
        <Case when={state.ads.length === 0}>
            { emptyResult || <div>Nenhum anúncio encontrado</div>}
        </Case>
        <Otherwise>
            <If test={grouping === "status"}>
                <Then>
                    <AdGroup ads={state.ads} status="draft" title="Anúncios em modo rascunho"/>
                    <AdGroup ads={state.ads} status="published" title="Anúncios publicados"/>
                    <AdGroup ads={state.ads} status="pending" title="Anúncios em revisão"/>
                    <AdGroup ads={state.ads} status="suspended" title="Anúncios suspensos"/>
                </Then>
                <Else>
                    <AdGroup ads={state.ads} />
                </Else>
            </If>
            { afterNonEmptyList }
            <If test={statePageSize * state.page < state.count}>
                <button type='button' className="more" onClick={nextPage}>Carregar mais anúncios...</button>
            </If>
        </Otherwise>
    </Switch>;
}

export default AdList;
