import React from 'react';

import { useHistory, useRouteMatch } from "react-router-dom";

import { Document, useEffect } from "../utils/reactUtils.js";
import { getAds, getMessages, delAd } from "../utils/service.js";
import { useMessageContext } from "../components/msg";

import AdEditor from '../pages/myads/adeditor';
import AdList from '../components/adlist.js';
import AdView from '../components/adview';
import { Switch, Case, Otherwise } from '../components/switch';
import { If } from '../components/fields/If';
import Error404 from '../pages/error404';

function RelatedAds({ ad }) {

    return ad && <aside className='related-ads'>
        <h4>Anúncios relacionados</h4>
        <section className="adcover">
            <AdList pageSize={3} exclude={ad.id}
                search={[
                    ad.category.name, ad.title, ad.want, ad.moreinformation
                ].join(" ")} />
        </section>
    </aside>;
}

function checkResult(defaultErrorMessage) {
    return result => (result && result.success) ? result.data :
        Promise.reject((result && result.data) || defaultErrorMessage);
}

// Máscara para permitir introdução de novas flags de forma mais fácil para o
// desenvolvedor (Evita definição manual do valor de cada flag)
let FLAG=128;
// Flag para indicar se é permitido remover com base no estado da remoção
const REMOVE_ENABLED = FLAG<<=1;
// Flag indicando se uma operação de remoção está pendente ou em andamento
const REMOVING = FLAG<<=1;
// Flag indicando se uma operação de leitura ainda está pendente ou em andamento
const LOADING = FLAG<<=1;
// Flag indicando que ocorreu um erro
const ERROR = FLAG<<=1;

// Contador para permitir introdução de novos estados de forma mais fácil para o
// desenvolvedor (Evita definição manual do valor de cada estado)
let STATE_COUNT = 0;
// Requisição para carregar os dados será enviada ao servidor durante
// renderização. Permanecerá neste estado até chegar resposta do servidor.
const LOAD_REQUEST = STATE_COUNT++ + LOADING;
// Houve erro ao carregar o anúncio
const LOAD_ERROR = STATE_COUNT++ + ERROR;
// Dados foram carregados do servidor. Solicitar remoção é possível
const IDLE = STATE_COUNT++ + REMOVE_ENABLED;
// Usuário solicitou remoção. Requisição para remover será enviada ao servidor
// durante renderização. Permanecerá neste estado até chegar resposta do servidor.
const REMOVE_REQUEST = STATE_COUNT++ + REMOVING;
// Remoção bem sucedida realizada no servidor. Nova solicitação de remoção para
// o mesmo Ad não é possível, pois ele já foi removido. O comportamento esperado
// é o aplicativo sair da página do Ad.
const REMOVE_DONE = STATE_COUNT++;
// Houve erro no servidor durante a operação de remoção.
// É possível solicitar novavente a remoção
const REMOVE_ERROR = STATE_COUNT++ + REMOVE_ENABLED + ERROR;
// Define o estado inicial
const START = LOAD_REQUEST;

function Ad() {

    const history = useHistory();
    const { url, params: { username, ad_slug, mode }} = useRouteMatch();
    const { showError, showSuccess } = useMessageContext();
    const [ state, setState ] = React.useState({
        ad: null, username, ad_slug, operationalState: START
    });
    const { ad, operationalState } = state;
    const adId = ad && ad.id;
    const loading = Boolean(operationalState & LOADING);
    const removeEnabled = Boolean(operationalState & REMOVE_ENABLED);
    const notFound = !ad && (operationalState === IDLE);

    function exitEditMode(ad) {
		setState({...state, operationalState: LOAD_REQUEST});
		history.replace(url.replace(/(\/[^\/]+\/editar)?$/, "/" + ad.slug));
	}

    function editAd() {
        history.replace(url.replace(/\/?$/, "/editar"));
    }

    function loadAd() {
        setState({ ...state, operationalState: LOAD_REQUEST });
    }

    const removeAd = !removeEnabled ? undefined : function removeAd() {
        if (window.confirm("Atenção! A exclusão do anúncio não poderá ser desfeita.")) {
            setState({...state, operationalState: REMOVE_REQUEST });
        }
    };

    useEffect(ifMounted => {

        function setOperationalState(operationalState, error) {
            setState(state => ({ ...state, operationalState, error }));
        }

        switch (operationalState) {
            case LOAD_REQUEST:
                getAds({ username, ad: ad_slug })
                    .then(result => (result && result.data) ||
                        Promise.reject("Não foi possível carregar as informações"))
                    .then(list => list[0])
                    .then(ifMounted(ad => ad &&
                        Promise
                            .all(ad.auati ? ad.auati.map(auati =>
                                getMessages(auati.id)
                                    .then(checkResult)
                                    .then(messages => auati.messages = messages)
                            ) : [])
                            .then(() => ad)
                    ))
                    .then(ifMounted(ad => setState({
                        ad, username, ad_slug,
                        operationalState: IDLE
                    })))
                    .catch(error => {
                        setState({
                            ad: null, username, ad_slug,
                            operationalState: LOAD_ERROR, error
                        });
                    });
                break;
            case REMOVE_REQUEST:
                delAd(adId)
                    .then(checkResult("Erro ao excluir anúncio"))
                    .then(ifMounted(
                        () => setOperationalState(REMOVE_DONE)))
                    .catch(ifMounted(
                        error => setOperationalState(REMOVE_ERROR, error)));
                break;
            case REMOVE_DONE:
                showSuccess("Anúncio removido.");
                setOperationalState(IDLE);
                history.goBack();
                break;
            default:
                break;
        }
        if ((operationalState & ERROR) && state.error)  {
            showError(state.error);
            setOperationalState(IDLE);
        }
    }, [
        username, ad_slug, operationalState, adId,
        state.error, showSuccess, showError, history
    ]);

    return <Document title={`Auati - ${ad ? ad.title : ad_slug}`}>
        <main className="ad">
            <Switch>
                <Case when={loading}>
                    <div>Carregando...</div>
                </Case>
                <Case when={notFound}>
                    <Error404 />
                </Case>
                <Case when={ mode === "editar"}>
                    <AdEditor adParam={{ username: username, ad: ad_slug }} mode="edit" onSave={exitEditMode} />
                </Case>
                <Otherwise>
                    <If test={ad}>
                        <AdView ad={ad} onEditAdClick={editAd} onRemoveAdClick={removeAd} loadAd={loadAd}/>
                        <RelatedAds ad={ad} />
                    </If>
                </Otherwise>
            </Switch>
        </main>
    </Document>;
}

export default Ad;
