import React from "react";
import GlobalContext from '../globalContext.js';
import { addUser } from "../utils/service.js";
import { isLogged, getUserData } from "../utils/JWTAuth.js";
import illustration from "../img/illustrations/new-mail.svg";

/**
 * TODO
 * Tratar mensagem de erro (depois que aparece, toca o som quando a gente digita)
 * Inserir uma ilustração nesta página
 */

class FormSignup extends React.Component {
    constructor(props) {
        super (props);

		this.timerId = null;
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.togglePassword = this.togglePassword.bind(this);
		this.PassRequirements = this.PassRequirements.bind(this);
		this.backToLogin = this.backToLogin.bind(this);

		const dataFromLocalStorage = localStorage.getItem("newUserData");

		let data = dataFromLocalStorage ? JSON.parse(dataFromLocalStorage) : {
			nickname: "",
			email: "",
            password: ""
		};

		this.state = {
			showPassword: false,
			data: data,
            created: false,
            newUser: null, 
            loading: true
		};
        this.userData = getUserData();
        this.isLogged = isLogged();
        
    }

	static contextType = GlobalContext;

    componentDidMount() {
        this.setState({loading: false});
    }
    
	togglePassword() {
        this.setState({showPassword: !this.state.showPassword});
	}
    
	async handleSubmit(event) {
        event.preventDefault();
		const form = event.target;
		const formData = new FormData(form);
        this.setState({loading: true});
		
		let result = await addUser(formData);
		if (result.success) {
            localStorage.removeItem("newUserData");
            this.setState({ 
                created: true ,
                newUser: result.data
            });
            this.context.setNewUser(result.data);
		} else {
            this.context.showError(result.data);
		}
        this.setState({loading: false});
	}

	handleInputChange(event) {
		const el = event.target;
		const name = el.name;
		const value = el.type === 'checkbox' ? (el.checked?1:0) : el.value;
		let data = this.state.data;
		data[name] = value;
		this.setState({ data: data });
		localStorage["newUserData"] = JSON.stringify(data);
		return el;
	}

    PassRequirements () {
        const pass = this.state.data.password;
        let min = /^.{8,}$/.test(pass);
        let upp = /(?=.*[A-Z])/.test(pass);
        let low = /(?=.*[a-z])/.test(pass);
        let num = /(?=.*[0-9])/.test(pass);
		const isValid = (min && upp && low && num);
        const format = (text, valid) => (valid ? text : <b style={{color:'var(--color-error)'}}>{text}</b>);
        return (<>

		{isValid && (<span style={{color:'var(--color-success)'}} >
            Esta é uma boa senha!
        </span>)}

		{!isValid && (<span>
            A senha deve ter no {format("mínimo 8 caracteres", min)}, {format("letras maiúsculas", upp)}, {format("minúsculas", low)} e {format("números", num)} .
            {min}
        </span>)}

        </>);
    }

    backToLogin() {
        this.context.openDialog('login');
	}

    render() {
		const type = this.state.showPassword ? 'text' : 'password';
        return (<>
            {!this.state.created && (
            <form autoComplete="off" onSubmit={this.handleSubmit}>
                <fieldset>
                    <div className="fields">
                        
                        <div className='field input type-text nickname'>
                            <label htmlFor="nickname">Nome</label>
                            <input required 
                                id='nickname'
                                name='nickname' 
                                type="text" 
                                value={this.state.data.nickname} 
                                onChange={this.handleInputChange} 
                                pattern="^(?!\d+$)[a-zA-Z0-9 _-]{3,}$"
                            />
                            <p>Escreva o seu apelido ou como gosta de ser chamado.</p>
                        </div>
                        
                        <div className='field input type-email email'>
                            <label>Seu e-mail</label>
                            <input required autoComplete="off" name='email' type="email" value={this.state.data.email} onChange={this.handleInputChange} />
                            <p>Enviaremos uma confirmação para seu e-mail.</p>
                        </div>

                        <div className='field input type-tel tel'>
                            <label>Seu telefone</label>
                            <input required autoComplete="off" name='tel' type="tel" value={this.state.data.tel} onChange={this.handleInputChange} />
                            <p>Faremos contato pelo <i>WhatsApp</i>.</p>
                        </div>

                        <div className='field input password'>
                            <label htmlFor="password">Senha:</label>
                            <input required name='password' type={type} 
                                id='password'
                                value={this.state.data.password} 
                                onChange={this.handleInputChange} 
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$"
                                title="Crie uma senha segura, com no mínimo 8 caracteres, letras maiúsculas, minúsculas e números." 
                            />
                            <button type='button' className={'togglepass '+type} onClick={this.togglePassword}></button>
                            <p>
                                {<this.PassRequirements />}
                            </p>
                        </div>

                        <div className="field">
                            <span>Ao criar sua conta você concorda e aceita 
                                 nossos <a href="/termos-de-uso" target="_blank">Termos de Uso</a>
                            </span>
                        </div>

                    </div>
                </fieldset>
                
                <div className="buttons">
                    <button type="submit" disabled={this.state.loading}>
                        <span>Criar conta</span>
                    </button>
                    <p>
                        Já possui uma conta? <button className="link" onClick={this.backToLogin}>Faça login</button>
                    </p>
                </div>
                
            </form>
            )}
            {this.state.created && (<>
            <figure className="illustration">
                <img src={illustration} alt="new e-mail" />
            </figure>
            <h2>
                Confira seu e-mail
            </h2>
            <p>Sua conta foi criada.</p>
            <p>
                Basta abrir o e-mail que mandamos para <b>
                {this.state.newUser.email}</b> e clicar 
                no link de confirmação para ativar sua conta.
            </p>
            <div className="buttons">
                <button className="button" onClick={this.props.close}>Fechar</button>
            </div>
            </>)}
        </>);
    }
}

export default FormSignup;