import React from 'react';
import logo from "../img/logo-auati.svg";
import ods1 from '../img/content/ods_1_pt.jpg';
import ods8 from '../img/content/ods_8_pt.png';
import ods12 from '../img/content/ods_12_pt.png';
import imgFapes from "../img/content/fapes.png";
import imgInovativa from "../img/content/inovativa.png";
import imgMoNacOds from "../img/content/mov-nac-ods.png";
import imgNaturaStartups from "../img/content/natura-startups.png";
import imgRedePocante from "../img/content/rede-pocante.png";
import imgSebrae from "../img/content/sebrae.png";
import imgSempreDesign from "../img/content/sempre-design.png";
import imgSoftex from "../img/content/softex.png";
import imgTecvitoria from "../img/content/tecvitoria.png";
import imgMilhoAuati from "../img/content/milho-auati.png";


class About extends React.Component {
    render() {
        return  (
			<main className="about">
				<article>


                    <h1>Sobre o projeto Auati</h1>


                    <div className='abstract'>
                        <p>
                        Um projeto multimoedas, de impacto e que pretende 
                        colaborar com o fortalecimento das novas economias.
                        </p>
                    </div>
                    
                    <p>
                        A plataforma AUATI é um marketplace virtual que conecta 
                        profissionais ou empresas que tenham interesse comum em 
                        realizar permuta total ou parcial de serviços. É um 
                        projeto de impacto social que tem o propósito de 
                        empoderar profissionais a acreditarem que “o seu trabalho 
                        é a sua moeda".
                    </p>
                    <p>
                        A iniciativa beneficia profissionais que queiram fazer 
                        negócio sem mexer no caixa para realizar a transação. 
                        Beneficia também profissionais que estão desempregados, 
                        possuem tempo ocioso ou que desejam ter acesso a bens e 
                        serviços que seu dinheiro não consegue comprar. 
                    </p>
                    <p>
                        O Auati tem como diferencial tecnológico a introdução da 
                        modalidade comercial mais antiga da humanidade, a permuta,
                        num ambiente virtual seguro, que permite que as pessoas 
                        troquem serviços de forma direta e com fácil usabilidade. 
                        A plataforma de permutas livres tem o potencial de 
                        democratizar o acesso a serviços, permitindo que pessoas 
                        com habilidades diferentes possam colaborar entre si e se 
                        ajudar mutuamente, sem precisar depender exclusivamente de 
                        recursos financeiros. 
                    </p>
                    <p>
                        Para participar, basta se cadastrar e informar qual 
                        trabalho realiza e quais tipos de serviços gostaria de 
                        adquirir por meio de permuta total ou parcial. Cada 
                        profissional terá um anúncio e uma página para divulgar 
                        e compartilhar o seu trabalho. E poderá navegar pelas 
                        oportunidades da plataforma e escolher quais perfis lhe 
                        interessa, curtir, enviar mensagens e iniciar negociações 
                        diretamente. A plataforma não se envolve com a negociação 
                        entre as partes e entende que seu papel é o de conectar 
                        profissionais com propósitos em comum.
                    </p>

                    <p>
                        E você? O que você faz e que gostaria de trocar?
                    </p>
                    <p>
                        <a href="/signup">Cadastre-se</a> no Auati 
                        e faça bons negócios!
                    </p>

                    
                    <h2>Trabalhamos para os ODS</h2>
                    <p>
                        Estamos em alinhamento com os Objetivos de Desenvolvimento
                        Sustentável da ONU/Agenda 2030.
                    </p>
                    <ul className='horizontal'>
                        <li>
                            <img src={ods1} alt="" style={{width: '8em'}}/>
                        </li>
                        <li>
                            <img src={ods8} alt="" style={{width: '8em'}}/>
                        </li>
                        <li>
                            <img src={ods12} alt="" style={{width: '8em'}}/>
                        </li>
                    </ul>
                    <p>
                        <img src="" alt="" />
                        Somos signatários do Movimento Nacional ODS/ES.
                        Uma rede que une representantes de todas as áreas da 
                        sociedade, voluntários e organizações que acreditam 
                        no desenvolvimento sustentável.
                    </p>
                    

                    <h2>
                        Acreditam e apoiam este projeto
                    </h2>
                    <p>Orgulhosamente, apresentamos os nossos parceiros.</p>
                    <ul className='horizontal'>
                        <li className='card'><img src={imgTecvitoria} alt="Tecvitória" /></li>
                        <li className='card'><img src={imgSebrae} alt="Sebrae" /></li>
                        <li className='card'><img src={imgSempreDesign} alt="Sempre Design" /></li>
                        <li className='card'><img src={imgNaturaStartups} alt="Natura Startups" /></li>
                        <li className='card'><img src={imgFapes} alt="Fapes" /></li>
                        <li className='card'><img src={imgInovativa} alt="Inovativa Brasil" /></li>
                        <li className='card'><img src={imgSoftex} alt="Softex" /></li>
                        <li className='card'><img src={imgMoNacOds} alt="Movimento Nacional ODS" /></li>
                        <li className='card'><img src={imgRedePocante} alt="Pocante" /></li>
                    </ul>

                    <h2>Conheça mais sobre o Auati</h2>
                    <img src={imgMilhoAuati} alt="Significado do nome Auati"
                    className='img-paragraph' />
                    <p>
                        Auati significa "milho" na língua Tupi Guarani <span role='img' aria-label='milho'>🌽</span>.
                    </p>
                    <p>
                        Sendo considerado o alimento básico de várias civilizações, o milho também foi muito utilizado como um importante instrumento de troca nos escambos. Escambo é uma atividade de troca que era utilizada quando ainda não havia sistema monetário. Essa troca, conhecida também como permuta ou troca direta, envolvia apenas coisas, serviços ou ambos. Muito comum entre a comunidade indígena, durante a colonização do Brasil.
                    </p>
                    <p>
                        O termo Auati também era conhecido como "o sustento da vida" para as civilizações antigas. Sua simbologia remete a prosperidade e a colaboração.
                    </p>
                    <p>
                        No desenvolvimento da marca do Auati utilizamos 3 grãos de milho nas cores amarelo, azul e rosa representando a diversidade de profissionais da plataforma e a forma como eles interagem e se conectam para gerar prosperidade dentro de um novo conceito de economia multimoeda, que se apresenta como uma alternativa ao modelo de consumo tradicional e se faz tão importante nesse novo mundo.
                    </p>


                    <footer>
                        <img src={logo} alt="Logo Auati" title="Auati - seu trabalho, sua moeda." />
                        <h3>
                            Auati - seu trabalho, sua moeda.
                        </h3>
                    </footer>

                </article>
			</main>
		);
    }
}

export default About;