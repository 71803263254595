import React from "react";

import InputImage from "./InputImage";
import { useEffect } from "../../utils/reactUtils";

const renderInput = (
    inputType,
    {
        id, fieldName, placeholder, title, required, checked,
        pattern, accept, limit, fieldValue, onChange, record
    }
) => <input
            name={fieldName}
            id={id || fieldName}
            type={inputType}
            required={required}
            value={fieldValue}
            onChange={
                onChange ||
                ( event => {
                    const { target } = event;
                    record.setFieldValue(
                        fieldName, target ? target.value : event.value);
                })
            }
            pattern={pattern}
            placeholder={placeholder}
            title={title}
            accept={accept}
            maxLength={limit}
			checked={checked}
/>;

function renderTextInput(inputType, { fieldValue, ...props }) {
	return renderInput(inputType, { ...props, fieldValue: fieldValue || ""});
}

function Select({
    record, id, fieldName, placeholder, title, required,
    options
}) {
    const [ theOptions, setOptions ] = React.useState([]);

    useEffect(ifMounted => {
		Promise.resolve(typeof options === "function" ? options () : options)
			.then(ifMounted(setOptions));
    }, [ options ]);
    return <select
        name={ fieldName } id={ id || fieldName }
        required={ required }
        value={ record.getFieldValue(fieldName) }
        onChange={ record.changeFieldValue(fieldName) }
        title={ title }
    >
        {placeholder && <option key="" value="">{placeholder}</option>}
        {theOptions.map(option => (
            <option key={option.id} value={option.id}>
                {option.name}
            </option>
        ))}
    </select>;
}


const controls = {

    "text": {
        renderControl: props => renderTextInput("text", props)
    },
    "email": {
        renderControl: props => renderTextInput("email", props)
    },
    "tel": {
        renderControl: props => renderTextInput("tel", props)
    },
    "number": {
        renderControl: props => renderTextInput("number", props)
    },
    "date": {
        renderControl: props => renderTextInput("date", props)
    },
    "file": {
        renderControl: props => renderTextInput("file", props)
    },
    "check": {
        renderControl: ({ fieldValue, onChange, ...props }) =>
            renderInput("checkbox", {
                ...props,
				fieldValue: fieldValue,
				checked: Boolean(fieldValue),
                onChange: onChange || (event => {
                    const { target } = event;
                    let value = target ? (target.checked ? 1 : 0) : event.value;
                    props.record.setFieldValue(props.fieldName, value);
                })
            })
    },
    "password": {
        renderControl: props => renderInput(
            props.showPassword ? "text" : "password", props)
    },
    "radio": {
        renderControl: ({
            record, fieldName, fieldValue, label, title, required, options
        }) => options.map(option => <label key={option.value}>
                <input
                    type="radio"
                    value={ option.value }
                    onChange={ record.changeFieldValue(fieldName) }

                    name={ fieldName }
                    id={ fieldName + '-' + option.value }
                    required={ required }
                    title={ title }
                    checked={
                        // eslint-disable-next-line eqeqeq
                        option.value == fieldValue
                    }
                />
                <span>{option.name}</span>
            </label>)
    },
    "image": {
        renderControl: props => <InputImage {...props}/>
    },
    "select": {
        renderControl: props => <Select {...props}/>
    },
    "textarea": {
        renderControl: ({
            record, id, fieldName, fieldValue, placeholder, title, required
        }) => {
			return fieldName && (
				<textarea name={ fieldName }
					id={ id || fieldName }
					required={ required }
					value={ fieldValue }
					onChange={ record.changeFieldValue(fieldName) }
					placeholder={ placeholder }
					title={ title }
				/>
			);
		}
    },
	"dumpJSON": {
		renderControl: ({ fieldValue }) => JSON.stringify(fieldValue)
	}
};

export { controls };