
const stringCompare = function getStringCompare(defaultCompare) {
    try {
        return Intl.Collator().compare;
    } catch(ex) {

    }
    return defaultCompare;
} ((a,b) => a > b);

function stringCompareProperty(propertyName) {
    return (a,b) => stringCompare(a[propertyName], b[propertyName]);
}

export default stringCompare;
export { stringCompare, stringCompareProperty };