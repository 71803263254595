import React from 'react';
import { setLike, getLikes } from "../utils/service.js";
import { getUserData } from "../utils/JWTAuth.js";
import GlobalContext from '../globalContext.js';


class LikeButton extends React.Component {

    constructor(props) {
        super(props);

        this.doLike = this.doLike.bind(this);

        this.state = {
            liked: !!this.props.ad.liked && this.props.ad.liked[0]['active'],
            likes: this.props.ad.likes,
            loadingLike: false
        }
    }

	static contextType = GlobalContext;

    async doLike() {
        this.setState({
            loadingLike: true
        });
        const data = getUserData();
        if (data.id) {
            const formData = new FormData();
            formData.append('id_user',  data.id);
            formData.append('id_ad',  this.props.ad.id);
            const result = await setLike(formData, this.props.ad.id);
            if (!result.success) {
                this.setState({ loadingLike: false });
                return this.context.setMsg('Não foi possível dar like.', 'error');
            }
            const likes = await getLikes(this.props.ad.id);

            this.setState({
                liked: result.data[0].active,
                likes: likes
            });
            this.setState({ loadingLike: false });
        } else {
            this.context.openDialog('login');
        }
        this.setState({
            loadingLike: false
        });
    }


    render() {
        const likeClasses = [
            'like',
            (this.state.liked ? 'selected' : null),
            (this.state.loadingLike ? 'loading' : null)
        ]
        const likeclass =  likeClasses.join(" ");
        return (
            <button className={likeclass} onClick={this.doLike}>
                <span>{this.state.likes}</span>
            </button>
        )
    }
}

export default LikeButton;