import React from 'react';

class Help extends React.Component {
    render() {
        return  (
			<main className="help">
                <h1>Ajuda</h1>
				<section>
                    Texto da ajuda.
                </section>
			</main>
		);
    }
}

export default Help;