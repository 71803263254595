import React from 'react';
import { withRouter } from "react-router-dom";
import { getAuatis } from "../utils/service.js";
import { getUserData } from '../utils/JWTAuth.js';
import DialogChat from '../components/dialogChat.js';
import GlobalContext from '../globalContext.js';
import { formatDate, capitalize } from '../utils/convert.js';

class MyAuatis extends React.Component {


    constructor(props) {

        super(props);

        this.state = {
            auatis: [],
            loading: true
        };
        this.userData = getUserData();

        this.status = {
            requested: "Solicitado",
            rejected: "Solicitação recusada",
            accepted: "Em negociação",
            agreed: "Em execução",
            rating: "Em avaliação",
            done: "Concluído"

        }
    }

    static contextType = GlobalContext;

    async componentDidMount() {
        let result = await getAuatis(this.userData.person.id);
        this.setState({
            auatis: (result && result.success) ? result.data : [],
            loading: false
        });
    }

    openChat(auati) {
        const data = this.userData;
        if (data.id) {
            this.context.openDialog({
                name: 'chatDialog',
                title: 'Negocie a troca de serviços',
                modal: false,
                content: <DialogChat
                    auati={auati}
                    setOption={this.setOption}
                    optionSelected={this.state.optionSelected}
                    loadAd={this.props.loadAd}
                    close={this.context.closeDialog} 
                />
            });
        } else {
            this.context.openDialog('login');
        }
    }

    PosterAds({ ad }) {
        const poster = ad.poster ? <img src={`/images/ad/${ad.poster}`} alt="" /> : <span>Não foi escolhido um serviço para trocar.</span>;
        const title = ad.title ? ad.title : '';
        return (
            <figure>
                <div>
                    {poster}
                </div>
                <figcaption>
                    {title}
                </figcaption>
            </figure>
        ) || '';
    }

    render() {
        const lastMessage = (a) => a.messages[a.messages.length - 1];
        const status_text = (a) => {
            const requester_name = a.person_requester.id_user === this.userData.id_user ? "você" : a.person_requester.nickname;
            switch (a.status) {
                case 'accepted':
                    return capitalize(requester_name) + " aceitou iniciar a negociação.";
                case 'requested':
                    return (<>
                    <span>Solicitado por </span>
                    <b>{requester_name}</b>.
                    </>)
            
                default:
                    break;
            }
        }
        const status_title = (a) => this.status[a.status];
        return (
            <main className="my-auatis">
                <section>
                    <h1>Meus Auatis</h1>
                    {
                        (this.state.loading &&
                            <h2>Carregando...</h2>) ||
                        
                        (this.state.auatis.length && <ul className="auatis">{
                            this.state.auatis.map(a => (
                                <li className='card' key={a.id}>
                                    <div className='posters'>
                                        <this.PosterAds ad={a.ad_requested} />
                                        <this.PosterAds ad={a.ad_requester} />
                                    </div>
                                    <div className='information'>
                                        <h2>{status_title(a)}</h2>
                                        <p className='requester'>
                                            {status_text(a)}
                                        </p>
                                        {a.messages.length > 0 && (
                                        <p className='last-message'>
                                            <span>Em {formatDate(lastMessage(a)['date_created'], 'short')}: </span>
                                            <b>"{lastMessage(a)['text']}"</b>
                                        </p>
                                        )}
                                        <button type='button' className='messages' onClick={() => this.openChat(a)}>Ver mensagens</button>
                                    </div>
                                </li>
                            ))
                        }</ul>) ||
                        <h2>Você não possui Auatis ainda.</h2>
                    }
                </section>
            </main>
        );
    }
}

export default withRouter(MyAuatis);