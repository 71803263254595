import { v4 as uuidv4 } from "uuid";

function readAsData(file, type) {
    return type === 'blob' ? readAsBlob(file) : readAsDataURL(file);
}

function readAsDataURL(file) {
    return new Promise(function executeReadAsDataURL(resolve, reject) {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
}

function readAsBlob(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const blob = new Blob([reader.result], { type: file.type });
            resolve(blob);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });
}

const db = new Promise(function openIndexedDB(resolve, reject) {

    const dbName = "auati.db";
    const migrations = [
        function v0(db) {
            db.createObjectStore('images', { keyPath: 'id' });
        }
    ];
    const dbVersion = migrations.length;

    function upgradeNeeded(event) {
        const db = event.target.result;
        var i, l, migration;
        for (i = event.oldVersion, l = migrations.length; i < l; i++) {
            migration = migrations[i];
            if (migration) {
                migration(db);
            }
        }
    }

    const openRequest = indexedDB.open(dbName, dbVersion);
    openRequest.onsuccess = function onSuccess(event) {
        resolve(event.target.result);
    };
    openRequest.onerror = reject;
    openRequest.onupgradeneeded = upgradeNeeded;
});

function indexedDBRequest(options) {
    return new Promise(function processDBRequest(resolve, reject) {
        db.then(db => {
            const storeNames = [ options.storeList ].flat();
            const transaction =
                db.transaction(storeNames, options.mode || "readonly");
            const stores =
                storeNames.map(name => transaction.objectStore(name));
            const requestList =
                [ options.requestList.apply(this, stores) ].flat();
            let result = new Array(requestList.length);
            requestList.forEach((request, index) => {
                request.onsuccess = function onSuccess(event) {
                    result[index] = request.result;
                };
                request.onerror = reject;
            });
            transaction.oncomplete = function onSuccessfulTransaction(event) {
                resolve(result);
            };
            transaction.onerror = reject;
        })
        .catch(reject);
    })
    .then(resultList => options.mapResult ? options.mapResult(resultList) :
        ((resultList.length === 1) ? resultList[0] : resultList)
    );
}

function loadImage(id) {

    return indexedDBRequest({
        storeList: [ "images" ],
        requestList: images => [ images.get(id) ]
    });
}

function saveImage(imageFile) {
    const id = uuidv4();
    const creationDate = Date.now();
    return readAsData(imageFile, 'blob')
        .then(data => {
            const result = {
                id, creationDate, name: imageFile.name, data
            };
            return indexedDBRequest({
                mode: "readwrite",
                storeList: [ "images" ],
                requestList: images => images.add(result),
                mapResult: () => result
            });
        })
        .catch(err => console.error("Erro ao gravar imagem", err));
}

function removeImage(id) {
    return indexedDBRequest({
        mode: "readwrite",
        storeList: [ "images" ],
        requestList: images => images.delete(id)
    });
}

function removeAllImages() {
    return indexedDBRequest({
        mode: "readwrite",
        storeList: [ "images" ],
        requestList: images => images.clear()
    });
}

export { loadImage, saveImage, removeImage, removeAllImages };
