import React from 'react';
import LikeButton from './likebutton.js';
import ShareButton from './sharebutton.js';
import RatingButton from './ratingbutton.js';


class Actions extends React.Component {
    
    render() {
        const ad = this.props.ad;
        return (
            <div className="actions">
                <LikeButton ad={ad} />
                <RatingButton ad={ad} />
                <ShareButton ad={ad} />
            </div>
        )
    }
}

export default Actions;