const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const formatDate = (date=null, givenoOptions=null) => {
	/*
	const allOptions = {
		weekday: [ "narrow", "short", "long" ],
		era: [ "narrow", "short", "long" ],
		year: [ "2-digit", "numeric" ],
		month: [ "2-digit", "numeric", "narrow", "short", "long" ],
		day: [ "2-digit", "numeric" ],
		hour: [ "2-digit", "numeric" ],
		minute: [ "2-digit", "numeric" ],
		second: [ "2-digit", "numeric" ],
		timeZoneName: [ "short", "long" ]
	};
	*/
	let options, functionName = "toLocaleDateString";
	if (typeof givenoOptions !== 'object') {
		switch (givenoOptions) {
			case "full":
				options = {
					weekday: "long",
					day: "2-digit",
					month: "long",
					year: "numeric",
					hour: '2-digit',
					minute: '2-digit'
				};
				break;
			case "short":
				options = {
					hour: '2-digit',
					minute: '2-digit'
				};
				break;
			case "date":
				options = {};
				break;
			case "time":
				options = {
					hour: '2-digit',
					minute: '2-digit'
				};
				functionName = "toLocaleTimeString";
				break;
			default:
				options = {}
		}
	}
	const d = new Date(date);
	return d[functionName]("pt-BR", options); 
}

const firstLetters = (name, max=2) => {
	if (!name) {
		return name;
	}
	name = name.toString();
	let parts = name.split(" ");
	let initials = "";
	parts.forEach((part, i) => {
		if (i < max) {
			initials = initials + part.charAt(0).toUpperCase();
		}
	});
	return initials;
} 

const capitalize = (text) => {
	return text.charAt(0).toUpperCase() + text.slice(1); 
}

export { formatBytes, formatDate, firstLetters, capitalize };