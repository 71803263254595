import React from 'react';
// import { Link } from 'react-router-dom';

class Instrucoes extends React.Component {
    
    render() {
        return (
            <section className="instrucoes">
                <h2>Como funciona</h2>
                <ul>
                    <li>
                        <img src="images/pages/passo-pesquisa.svg" alt="Pesquise" />
                        <h4>Pesquise</h4>
                        <p>Busque e identifique as oportunidades mais interessantes para você.</p>
                    </li>
                    <li>
                        <img src="images/pages/passo-cadastro.svg" alt="Cadastre-se"/>
                        <h4>Cadastre-se</h4>
                        <p>Informe o que você faz ou vende e pelo que deseja trocar.</p>
                    </li>
                    <li>
                        <img src="images/pages/passo-mensagens.svg" alt="Troque mensagens" />
                        <h4>Converse</h4>
                        <p>Troque mensagens com o profissional ou a empresa que escolheu.</p>
                    </li>
                    <li>
                        <img src="images/pages/passo-faz-um-auati.svg" alt="Faz um Auati" />
                        <h4>Faça um Auati</h4>
                        <p>Realize a permuta e nos conte como foi essa experiência.</p>
                    </li>
                </ul>
            </section>
        )
    }
}

export default Instrucoes;