import { getCities, getStates, getCountries } from '../utils/service.js';
import { stringCompareProperty } from '../utils/stringCompare';

export default {

    "nickname": {
        type: "text",
        required: true,
        label: "Nome curto",
        pattern: "(?!\\d+$)[a-zA-Z0-9_. -]{3,}",
        instructions: "Escreva o seu apelido ou como gosta de ser chamado"
    },

    "fullname": {
        type: "text",
        required: true,
        label: "Seu nome completo",
        instructions: "Digite aqui seu nome completo"
    },

    "email": {
        type: "email",
        required: true,
        label: "Seu e-mail",
        instructions: "Enviaremos uma confirmação para seu e-mail"
    },

    "doc": {
        type: "text",
        required: true,
        label: "CPF",
        instructions: "Digite seu CPF"
    },

    "tel": {
        type: "text",
        required: true,
        label: "Seu Celular",
        instructions: "No formato (00) 00000-0000"
    },

    "about_me": {
        type: "text",
        controlType: "textarea",
        required: true,
        label: "Resumo",
        instructions: "Resuma seu trabalho em poucas palavras"
    },

    "nobrasil": {
        type: "number",
        controlType: "radio",
        label: "Mora no Brasil?",
        instructions: "",
        options: [
            {name:'Sim', value: 1},
            {name:'Não, moro no exterior', value: 0}
        ]
    },

    "avatar": {
        type: "image"
    },

    "state": {
        type: "text",
        controlType: "select",
		label: "Estado",
		placeholder: "Selecione",
		required: true,
		instructions: "Informe o estado de sua residência.",
		options: () => getStates()
            .then(response => response.success ? response.data :
                Promise.reject("Erro ao carregar lista de estados"))
            .then(states =>
                states.map( s => ({id: s.abbreviation, name: s.state})))
    },

    "city": ({ state }) => ({
        type: "text",
        controlType: "select",
		label: "Município",
		placeholder: state ? "Selecione" : "Selecione o estado",
		required: true,
		instructions: "Informe município onde mora.",
		options: () => !state ? Promise.resolve([]) :
                getCities(state)
                .then(response => (response && response.success) ? response.data :
                    Promise.reject("Erro ao carregar lista de municípios"))
                .then(cities => cities
                    .map( ({ city }) => ({id: city, name: city}))
                    .sort(stringCompareProperty("name")))
    }),

    "countrycode": {
        type: "text",
        controlType: "select",
        label: "País",
		placeholder: "Selecione",
		required: true,
		instructions: "Informe o país de sua residência.",
		options: () =>
            getCountries()
            .then(response => (response && response.success) ? response.data :
                Promise.reject("Erro ao carregar lista de países"))
            .then(countries => countries
                .filter((o) => o.code !== "BR")
                .map(c => ({id: c.code, name: c.name}))
            )
    },

    "password": {
        type: "password",
        required: true,
        label: "Nova senha",
        title: "A senha deve ter no mínimo 8 caracteres, letras maiúsculas, minúsculas e números."
    },
    "date_created": {
        type: "date"
    },
    "date_updated": {
        type: "date"
    }
};
