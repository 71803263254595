import React from 'react'
import { useParams } from 'react-router-dom';
import AdList from "../components/adlist"
import { getCategories } from '../utils/service';


function Category() {

    const [ { category, loading }, setState ] = React.useState({
        category: {}, loading: true
    });

    const { category: slug } = useParams();

    React.useEffect(function loadCategory() {
        if (loading && slug) {
            getCategories(slug)
                .then(function processResult(result) {
                    if (result && result.success) {
                        setState({
                            category: result.data, loading: false
                        });
                    }
                });
        }
    }, [ loading, category, slug ]);

    return (
        <main className='category'>
            <header style={{backgroundColor: category.color}}>
                {category.icon && <figure>
                    <img alt="" src={`images/icon/${category.icon.url}`} 
                    title={category.name} />
                </figure>}
                <h1>
                    <span>{category.name}</span>
                    <small>{`${category.ads_count} anúncio${ category.ads_count > 1 ? 's':''}.`}</small>
                </h1>
            </header>
            <section className="adcover">
                <AdList category={slug}/>
            </section>
        </main>
    );
}

export default Category;
