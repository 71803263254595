import React from 'react';
import GlobalContext from '../globalContext.js';
import { confirmEmail } from "../utils/JWTAuth.js";
import { withRouter, Link } from 'react-router-dom'


class Confirm extends React.Component {

	constructor(props) {
		super(props);

		const path = this.props.location.pathname;
		this.hash = path.split("/")[2];
        this.state = {
			situation: '',
			user: {},
			msgerror: ''
		};
        this.props.setLoading(true);

	}
	
	static contextType = GlobalContext;
	
	async componentDidMount() {
		document.title = "Confirmação de e-mail - Auati";
	    const result = await confirmEmail(this.hash);
		if (!result) {
			this.setState({
				situation: 'error',
				errorType: "general_error",
				msgerror: 'Falha ao carregar as informações.'
			});
            this.context.showError('Falha ao carregar as informações.');
		}
	    const success = result.success;
		if (success) {
			document.title = "Cadastro ativado! - Auati";
			this.setState({
				situation: 'success',
				user: result.data[0]
			});
		} else {
			this.setState({
				situation: 'error',
				errorType: result.data.code,
				msgerror: result.data.text
			});
            this.context.showError(result.data.text);
		}
		this.props.setLoading(false);
	}

	render() {

		return (
			<main className="confirm">
				<section>
				{this.state.situation === 'success' && (<>
                    <h1>Olá, {this.state.user.person.nickname}</h1>
                    <p>
                        Sua conta está ativa!
                    </p>
                    <p>
                        Não se esqueça de completar seu cadastro para 
                        desfrutar de todos os recursos do Auati!
                    </p>
					<button type='button' onClick={() => this.context.openDialog('login')}>Fazer login</button>
                </>)}
                
                {this.state.situation === 'error' && (<>
					{this.state.errorType !== 'email_alread_verified' && (
						<div>
							<h3>
								Não foi possível validar o e-mail.
							</h3>
							<p>O link pode estar mal formatado ou seu e-mail já foi validado.</p>
							<p className='error'>{this.state.msgerror}</p>
						</div>
					)}
					{this.state.errorType === 'email_alread_verified' && (
						<div>
							<h1>
								Seu e-mail já foi validado!
							</h1>
							<p>Já pode fazer login com o e-mail e senha que você cadastrou.</p>
							<Link className="submit" to="/minha-conta/perfil/editar">
								Fazer login
							</Link>
						</div>
					)}
				</>)}
				</section>
			</main>
		);
	}
}

export default withRouter(Confirm);