import React from 'react';
// import { Link } from 'react-router-dom';

class Stars extends React.Component {
    
    render() {
        // const ad = this.props.ad;
        return (
            <div className="stars">
                <div className='on' style={{width:'90%'}}></div>
            </div>
        )
    }
}

export default Stars;