import React from 'react';
import { withRouter } from "react-router-dom";
import { isLogged } from "../utils/JWTAuth.js";
import { getUsers, setUser } from "../utils/service.js";
import GlobalContext from "../globalContext";


class User extends React.Component {
	constructor (props) {
		super(props);
		this.timerId = null;
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleError = this.handleError.bind(this);
		this.handleChangePassword = this.handleChangePassword.bind(this);

		const id = this.props.location.pathname.split("/")[2];
		this.storageName = "userData" + id;
		const dataFromLocalStorage = localStorage.getItem(this.storageName);

		let data = dataFromLocalStorage ? JSON.parse(dataFromLocalStorage) : {
			id: id,
			nome: "",
			email: "",
			senha: "",
			tel1: "",
			tel2: "",
			preferecontatopor: "",
			horarioparacontato: "",
			senha2: "",
			papel: ""
		};

		this.state = {
			data: data,
			changePassword: false,
			found: null
		};

	    this.props.setLoading(true); 

	}

    static contextType = GlobalContext;

	async componentDidMount() {
		document.title = "Canal de Denúncias Unionlab - Dados do usuário";
		if (isLogged()) {
			let result = await getUsers(this.state.data.id);
			if (result && result.success) {
				let data = this.state.data;
				for(let prop in this.state.data) {
					if (result.data[prop]) {
						data[prop] = result.data[prop];
					}
				};
				this.setState({data: data });
			}
			if (!result || !result.success) {
				this.context.showError("Os dados do usuário não foram localizados.");
			}
			this.setState({ found: result && result.success });
		} else {
			this.props.history.push('/');
		}
	    this.props.setLoading(false); 
		window.addEventListener('beforeunload', this.handleLeavePage);
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.handleLeavePage);
	}

	handleLeavePage(e) {
		const confirmationMessage = 'Verifique se os dados foram salvos.';
		e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
		return confirmationMessage;              // Gecko, WebKit, Chrome <34
	}

	handleError(el, msg = 'Erro desconhecido') {
		el.classList.add('error');
		return this.context.showError(msg);
	}

	async handleSubmit(event) {
		event.preventDefault();
		this.props.setLoading(true);

		const form = event.target;
		const data = this.state.data;

		//compare password1 and password2
		if (this.state.changePassword && data.senha !== data.senha2) {
			return this.handleError(form['senha2'], "A senha e a confirmação de senha devem ser iguais.") && false;
		}
		
		const formData = new FormData(form);
		const id = data.id;

		if (!formData.has("id")) {
			formData.append("id", id);
		}
		const result = await setUser(formData, id);
		if (!result.success) {
			return this.context.showError(result.data || 'Não foi possível salvar seus dados.');
		}
		this.setState({
			changePassword: false
		});
		localStorage.removeItem(this.storageName);
		this.props.setLoading(false);
		return this.context.showSuccess('Seus dados foram salvos');

	}

	handleInputChange(event) {
		const el = event.target;
		const name = el.name;
		const value = el.type === 'checkbox' ? (el.checked?1:0) : el.value;
		let data = this.state.data;
		data[name] = value;
		this.setState({ data: data });
		localStorage[this.storageName] = JSON.stringify(data);
		return el;
	}
	
	handleChangePassword(event) {
		const el = event.target;
		const value = el.checked ? 1 : 0;
		this.setState({ changePassword: value });
	}

	render() {
		const papel = this.props.userData.papel;
		return this.state.found ? (
			<main className="user">
				<h1>
					<span>{this.state.data.nome}</span>
				</h1>
				<section className="formuser">
				<form autoComplete="off" onSubmit={this.handleSubmit}>
					<fieldset>
						<legend>
							Dados da conta de usuário
						</legend>
		                <div className="fields">
							<div className='field input text nome'>
								<span>Seu nome completo</span>
								<input required name='nome' type="text" value={this.state.data.nome} onChange={this.handleInputChange} />
							</div>
							<div className='field input email email'>
								<span>Seu e-mail</span>
								<input required autoComplete="off" name='email' type="email" value={this.state.data.email} onChange={this.handleInputChange} />
							</div>
							<div className='field input text tel1'>
								<span>Telefone 1</span>
								<input required id='tel1' name='tel1' type="text" value={this.state.data.tel1} onChange={this.handleInputChange} />
							</div>
							<div className='field input text tel2'>
								<span>Telefone 2</span>
								<input id='tel2' name='tel2' type="text" value={this.state.data.tel2} onChange={this.handleInputChange} />
							</div>
							<div className='field select preferecontatopor'>
								<span>Prefere contato por</span>
								<select required name='preferecontatopor' type="text" value={this.state.data.preferecontatopor} onChange={this.handleInputChange} >
			                        <option value="">Selecione o canal...</option>
			                        <option value="Telefone">Telefone</option>
			                        <option value="E-mail">E-mail</option>
			                        <option value="Whatsapp">Whatsapp</option>
			                    </select>
							</div>
							<div className='field select horarioparacontato'>
								<span>Melhor horário para contato</span>
								<select required name='horarioparacontato' type="text" value={this.state.data.horarioparacontato} onChange={this.handleInputChange} >
			                        <option value="">Selecione o horário...</option>
          							<option value="de manhã (entre 8 e 12 horas)">de manhã (entre 8 e 12 horas)</option>
          							<option value="de tarde (entre 13 e 17 horas)">de tarde (entre 13 e 17 horas)</option>
          							<option value="de noite (entre 18 e 22 horas)">de noite (entre 18 e 22 horas)</option>
          						</select>
							</div>
							<div className='field input checkbox changePassword'>
								<div>
									<input
									name="changePassword"
									id="changePassword"
									type="checkbox"
									checked={this.state.changePassword}
									onChange={this.handleChangePassword} />
									<label htmlFor="changePassword">Alterar a senha</label>
								</div>
							</div>
							{!!this.state.changePassword && (
							<>	
								<div />
								<div className='field input password senha'>
									<span>Senha</span>
									<input required name='senha' type="password" 
									value={this.state.data.senha} 
									onChange={this.handleInputChange} 
									pattern="^.{6,}$"
									title="A senha deve ter no mínimo 6 caracteres" />
								</div>
								<div className='field input password senha2'>
									<span>Confirmar senha</span>
									<input required name='senha2' type="password" 
									value={this.state.data.senha2} 
									onChange={this.handleInputChange} 
									pattern={this.state.data.senha}
									title="A confirmação da senha deve ser igual a senha informada." />
								</div>
							</>)}
							{ papel === 'Admin' && (
								<div className='field select papel'>
									<span>Atribuição do usuário</span>
									<select 
									required name='papel' 
									value={this.state.data.papel} 
									onChange={this.handleInputChange} >
										<option value="">Selecione o papel...</option>
										<option value="Autor">Autor - Apenas cria ocorrências</option>
										<option value="Editor">Editor - Responde ocorrências</option>
										<option value="Admin">Admin - Acesso a todas as funcionalidades</option>
									</select>
								</div>
							)}

						</div>

					</fieldset>
					
					
					<div className="buttons">
						<button type="submit" disabled={this.props.loading}>
							<span>Alterar dados do usuário</span>
						</button>
					</div>
					
				</form>
				</section>
			</main>
		) 
		:
		(
			<main className="myaccount">
				<section>
					Usuário não encontrado.
				</section>
			</main>
		)
	}
}

export default withRouter(User);