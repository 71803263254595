import React from 'react';

const SystemMessageContext = React.createContext();

function useMessageContext() {
    return React.useContext(SystemMessageContext);
};

function SystemMessageContextProvider({ children }) {

    const [ message, setMessage ] = React.useState({});

    function showError(message) {
        console.error(new Error("SHOWERROR: " + message));
		setMessage({ text: message, type: 'error' });
	}

	function showSuccess(message) {
		setMessage({ text: message, type: 'success'});
	}

    function setMsg(text, type) {
        setMessage({ text, type });
    }

    return <SystemMessageContext.Provider value={{
        message, setMessage, showError, showSuccess, setMsg
    }}>
        { children }
    </SystemMessageContext.Provider>;
}


function SystemMessageView({ children }) {

    const { message: { text: contextText, type, onClose }, setMessage }
        = useMessageContext();
    const text = (contextText instanceof Error) ?
            contextText.message : contextText;

    const close = React.useCallback(function close() {
        setMessage({});
        if (typeof onClose === "function") {
            onClose();
        }
    }, [ setMessage, onClose]);

    function playSound(type) {
        const audio = new Audio('./sounds/'+type+'.wav');
        Promise.resolve(audio.play()).catch(()=>0);
	}

    React.useEffect(() => {
        if (text) {
			const timeLimit = 5000 + (text.length * 75);
			const timeoutId = setTimeout(close, timeLimit);
            playSound(type);
            return () => clearTimeout(timeoutId);
		}
	}, [ text, close, type ]);

    return Boolean(text) && <div className={'msg ' + type}>
        <span>
            {text}
        </span>
        <button className="close" type="button" onClick={close} title="Fechar"></button>
    </div>;
}

export { useMessageContext, SystemMessageContextProvider, SystemMessageView };
