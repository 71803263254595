import React from "react";
import { getCategories } from '../utils/service';
import { stringCompareProperty } from '../utils/stringCompare';

export default {

	"title": {
        type: "text",
		label: 'Título do anúncio',
		placeholder: "Ex.: Limpeza de estofados",
		required: true,
		instructions: "Escolha um título curto e atraente sobre o que você faz.",
		title: "Escolha um título curto e atraente sobre o que você faz.",
		pattern: '.{3,64}'
	},

	"offer": {
        type: "text",
		label: 'O que você oferece?',
		placeholder: "Ex.: Higienização de estofados a seco, com produtos antialérgicos.",
		required: true,
		instructions: "Escreva em poucas palavras os serviços que você oferece.",
		title: "Escreva em poucas palavras o que você oferece neste anúncio.",
		pattern: '.{3,128}'
	},

	"moreinformation": {
        type: "text",
        controlType: "textarea",
		label: 'Mais informações',
		placeholder: "Exemplo: Ofereço limpeza de estofados à seco, impermeabilização de sofás, cadeiras, colchões, tapetes, bancos de veículos e outros estofados à domicílio.",
		required: true,
		instructions: "Escreva uma descrição do trabalho que você oferece. Agora é hora de vender o seu peixe, então capriche na descrição e destaque os pontos positivos do seu trabalho." ,
		pattern: '.{3,500}'
	},

	"id_category": {
        type: "text",
        controlType: "select",
		label: 'Categoria do seu trabalho',
		placeholder: "Selecione",
		required: true,
		instructions: "Escolha a categoria na qual seu trabalho se enquadre.",
		pattern: '.{3,64}',
		options: () => getCategories()
            .then(response => response.success ?
                response.data : Promise.reject("Erro ao carregar categorias"))
            .then(categories => categories.sort(stringCompareProperty("name")))
	},

	"remote": {
        type: "number",
        controlType: "radio",
		label: "Seu trabalho pode ser remoto?",
		required: true,
		title: 'Informe se seu trabalho pode ser feito online, pelo computador ou celular.',
		instructions: 'Informe se seu trabalho pode ser feito online, pelo computador ou celular.',
		options: [
			{name:'Sim', value: 1},
			{name:'Não, somente presencial', value: 0}
		],
		value: ""
	},

	"poster": {
        type: "image",
		label: "Imagem principal",
		required: true,
		title: 'Escolha uma imagem no tamanho 800 x 600 pixels.',
		instructions: 'Escolha uma imagem no tamanho 800 x 600 pixels que será usada como capa do anúncio.',
		maxItems: 1
	},

	"images": {
        type: "image",
		label: "Insira mais fotos do seu trabalho",
		required: false,
		title: 'Escolha até 6 imagens.',
		instructions: 'Escolha até 6 imagens para seu anúncio. Como dica, você pode colocar fotos do seu processo de trabalho ou dos resultados dele. Evite colocar fotos com outras pessoas, para evitar problemas de uso de imagem.',
		maxItems: 6
	},

	"want_categories": {
		label: "Tenho interesse em trocar meu trabalho por",
		required: true,
		instructions: "Escolha as categorias de serviços que deseja receber propostas."
	},

	"want": {
        type: "text",
        controlType: "textarea",
		label: 'Especifique o que gostaria de trocar',
		placeholder: "Gostaria de trocar por mecânica de automóveis, conserto de geladeira, serviços de dentista ou serviços de costura para ajustar algumas roupas.",
		required: true,
		instructions: "Informe especificamente pelo que você gostaria de trocar o seu trabalho.",
		pattern: '.{3,500}',
	},

	"agree": {
        type: "number",
        controlType: "check",
		label: "Ao publicar você aceita e concorda com os nossos termos de uso e privacidade.",
		required: true,
		title: 'É necessário aceitar os Termos de Uso o Privacidade para adicionar um anúncio.',
		instructions: <span>Leia aqui os <a href="/termos-de-uso">Termos de Uso e Privacidade</a>.</span>,
		options: [
			{name:'Aceito', value: 1},
			{name:'Não aceito', value: 0}
		],
		defaultValue: 0
	}
};
