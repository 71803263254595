import React from 'react';
import { withRouter } from 'react-router-dom'

class AdDone extends React.Component {

	componentDidMount() {
		document.title = "Auati - Anúncio publicado!";
	}

	render() {
		return (
			<main className="addone">
				
			</main>
		);
	}
}

export default withRouter(AdDone);