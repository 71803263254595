import React from 'react';
import logo from "../img/logo-auati.svg";

class Terms extends React.Component {
    render() {
        return  (
			<main className="terms">
				<article>


                    <h1>Termos e condições de uso do Auati</h1>


                    <div className='abstract'>
                        <p>
                            Olá! É muito importante para nós que você conheça os 
                            termos e as condições de uso do Auati, pois trazemos 
                            informações necessárias para que você tenha a melhor 
                            experiência possível. O seu sucesso é o nosso sucesso!
                        </p>
                        <p>
                            Temos o compromisso de usar uma linguagem simples e 
                            direta. Por isso, apresentamos um contrato resumido, 
                            com os principais pontos que você deve saber antes de 
                            aproveitar o Auati. Usar os serviços do Auati implica 
                            na compreensão, aceitação e vinculação automática a 
                            estes termos e condições de uso. 
                        </p>
                    </div>


                    <h2>
                        O que é o Auati:
                    </h2>
                    <p>
                        O Auati é uma plataforma digital para troca de serviços. Nela os 
                        profissionais podem se cadastrar para oferecer seus serviços e 
                        informar os serviços que querem receber em troca. Após o cadastro, 
                        as informações fornecidas pelos profissionais ficarão disponíveis 
                        no site Auati, para que sejam visíveis pelos mecanismos de busca e 
                        redes sociais. Os cadastros são feitos de forma livre e de inteira 
                        responsabilidade dos cadastrados e a eles competem a veracidade, a 
                        licitude e o teor do conteúdo publicado.
                    </p>


                    <h2>
                        O que você pode fazer no Auati: 
                    </h2>
                    <p>
                        Para fazer melhor uso do Auati e manter o ambiente em harmonia com o 
                        espírito de colaboração, troca e conexões positivas, procure falar com 
                        objetividade e informar com clareza e transparência o tipo de trabalho 
                        que oferece e quais serviços você quer em troca. Isso aumenta as chances 
                        de ter sucesso e conseguir mais conexões. Os usuários podem compartilhar 
                        os anúncios e divulgá-los nas redes sociais e outros meios de publicidade.
                    </p>
                    <p>
                        No site é possível ter acesso a todos os anúncios publicados e demonstrar interesse pelos serviços que deseja trocar. Com o consentimento de ambas as partes, os dados são fornecidos e daí em diante cabem às partes envolvidas negociarem a troca de serviços, que podem ou não envolver dinheiro. O Auati não se envolve nas negociações diretas, já que seu papel é de divulgar os profissionais e conectar as pessoas que têm interesse em realizar trocas dos seus serviços.
                    </p>
                    <p>
                        Independente do resultado das negociações, recomendamos que os usuários respondam como foi a troca de serviços, com o objetivo de colaborar para a melhoria da experiência.  Os depoimentos poderão ser publicados nas redes sociais do Auati para incentivar a cultura da troca de serviços.
                    </p>
                    <p>
                        O profissional pode, a qualquer momento, excluir sua conta no Auati, caso assim deseje. O cancelamento retira do site os anúncios e as demais informações do profissional em até 48 horas.
                    </p>


                    <h2>
                        O que você não deve fazer no Auati:
                    </h2>
                    <p> 
                        Os anúncios publicados no Auati são de inteira responsabilidade dos profissionais que forneceram as informações de livre e espontânea vontade. Trata-se de uma rede profissional e, portanto, cabe ao profissional usar textos ou imagens de assuntos relacionados exclusivamente às suas atividades profissionais. Também é expressamente proibido mensagens que incitem violência, racismo ou qualquer tipo de discriminação social ou de gênero. Não é permitido também o uso de termos chulos (como palavrões e obscenidades) ou o uso de textos, vídeos e imagens que violem os direitos autorais de terceiros. Ao publicar os anúncios, os profissionais se declaram titulares dos seus conteúdos.
                    </p>
                    <p> 
                        A plataforma Auati se reserva no direito de revisar e corrigir erros gramaticais e de remover do site os anúncios, textos e imagens que violem os direitos autorais de terceiros, assim como informações que incitem violência ou discriminação.
                    </p>

                    <h2>
                        Aplicabilidade
                    </h2>
                    <p>
                        O Auati é uma plataforma em desenvolvimento e poderá evoluir, tanto nas tecnologias utilizadas quanto no seu modelo de negócio. Isso significa que os termos de uso podem mudar para se adaptar ao seu funcionamento ou às mudanças da lei. Porém, todos os usuários serão notificados, caso isso ocorra, para lerem e avaliarem se concordam com os novos termos e decidirem se continuam a usar os serviços da plataforma.
                    </p>

                    <h2>
                        Conflitos
                    </h2>
                    <p>
                        O Auati acredita em seu slogan, “seu trabalho, sua moeda” e, por isso, torcemos para que as negociações ocorram com transparência e de forma justa. Acreditamos que dinheiro também é importante nas negociações de permuta, pois é com o dinheiro que pagamos as nossas contas. Então, incentivamos que as permutas sejam parciais e que favoreçam as duas partes. É importante frisar que a plataforma Auati não se envolve nas negociações entre as partes. Recomendamos, fortemente, que sempre procure referências sobre a idoneidade e nível de qualidade dos serviços do profissional que deseja trocar antes de fazer negócio. Também é recomendável que, antes de iniciar a troca, as partes façam um contrato mútuo, detalhando o objeto da troca e como ela acontecerá.
                    </p>
                    <p>
                        A plataforma não participa ou induz qualquer forma de negociação entre as partes. Isso fica totalmente a cargo do diálogo entre os envolvidos. Caso você entenda por demandar o Auati na justiça, a demanda deverá ocorrer no Foro da Comarca de Vitória, ES.
                    </p>
                    <footer>
                        <img src={logo} alt="Logo Auati" title="Auati - seu trabalho, sua moeda." />
                        <h3>
                            Auati - seu trabalho, sua moeda.
                        </h3>
                    </footer>
                </article>
			</main>
		);
    }
}

export default Terms;