import React from 'react';

import ListAd from "./listads";
import AdEditor from "./adeditor";
import { Switch, Case } from "../../components/switch";

function MyAds({ mode, setMode }) {

    function newAd() {
        setMode("novo");
    }

    function showAdList() {
        setMode("listar");
    }

    return (
        <Switch value={mode}>
            <Case when="listar">
                <ListAd onNewAdClick={newAd} />
            </Case>
            <Case when="novo">
                <AdEditor
                    mode="new" onSave={showAdList} onBackClick={showAdList}/>
            </Case>
        </Switch>
    );
}

export default MyAds;
