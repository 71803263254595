import React from 'react';

import AdList from '../../components/adlist';

function ListAd({ onNewAdClick }) {

    return <main id="listar" className="myads">
        <section>
            <h1>Meus anúncios</h1>
            <AdList
                my="true" grouping="status"
                emptyResult={<div className='empty'>
                    <p>
                        Você ainda não possui anúncios.
                    </p>
                    <p>
                        O que você sabe fazer? Crie um anúncio agora mesmo!
                    </p>
                    <button type='submit' onClick={onNewAdClick} >Começar</button>
                </div>}
                afterNonEmptyList={<button type='submit' onClick={onNewAdClick}>
                    Criar um novo anúncio
                </button>}/>
        </section>
    </main>;
}

export default ListAd;
