import React from "react";
import Record from "./Record";
import { useEffect } from "../../utils/reactUtils";

function SelectionInput(props) {
	const { fieldName, loadItems, renderItem, required } = props;
	const [ items, setItems ] = React.useState([]);
	const record = Record.use();
	const selectedIdList = (record && record.getFieldValue(fieldName)) || [];
	const getItemId = props.getItemId || (item => item.id);

	useEffect(ifMounted => {
		Promise.resolve(props.items || loadItems())
				.then(ifMounted(setItems));
	}, [ props.items, loadItems ]);

	function toggleSelect(item) {
		if (!record) return;
		const itemId = getItemId(item);
		const filtered = selectedIdList.filter(id => id !== itemId);
		record.setFieldValue(fieldName,
				filtered.length === selectedIdList.length ?
					[ ...filtered, itemId ].sort() : filtered);
	}

    const selectedIdSet = new Set();
    selectedIdList.forEach(id => selectedIdSet.add(id));

    function renderTextbox(itemId, selected) {
        return <input
            type="text"
            value={selectedIdList.join(",")}
            className="hidden"
            onChange={()=>0}
            required={required}
        />;
    }

    function renderCheckbox(itemId, selected) {
        return <input
            name={fieldName + "[]"}
            type="checkbox"
            value={itemId}
            checked={selected}
            className="hidden"
            readOnly={true}
            required={required}
        />;
    }

    let textboxDrawn = false;
    function renderCheckboxAndPossiblyTextbox(itemId, selected) {
        const checkbox = renderCheckbox(itemId, selected);
        return textboxDrawn ? checkbox : <React.Fragment>
            {(textboxDrawn = true, renderTextbox())}
            {checkbox}
        </React.Fragment>;
    }

	return <React.Fragment>
        {items.map(item => {
			const itemId = getItemId(item);
			const selected = selectedIdSet.has(itemId);
            let checkboxCalled = false;
            function checkbox() {
                return !checkboxCalled && ((checkboxCalled = true)) &&
                    renderCheckboxAndPossiblyTextbox(itemId, selected);
            }
            return <React.Fragment key={itemId}>
				{renderItem(item, selected, () => toggleSelect(item), checkbox)}
                {checkbox()}
			</React.Fragment>;
		})}
    </React.Fragment>;
}

export default SelectionInput;
