import React from 'react';
import { withTranslation } from 'react-i18next';
import { getCategories } from '../utils/service';
import SelectionInput from './fields/SelectionInput';

function Categories({ empty, action, fieldName, required }) {
	async function loadItems() {
		const response = await getCategories();
		if (response && response.success) {
			const categories = response.data;
			return empty ? categories :
				categories.filter(category => category.ads_count > 0);
		} else {
			return [];
		}
	}
	return <ul className="categories">
		<SelectionInput
			fieldName={fieldName}
			loadItems={loadItems}
            required={required}
			renderItem={(category, selected, toggleSelected, checkbox) => {
				return <li key={category.id}
					onClick={action ? () => action(category) : toggleSelected}
					className={selected ? 'selected' : 'unselected'}>
						<figure>
							<img alt=''
								style={{backgroundColor: category.color}}
								src={'images/icon/' + category.icon.url}
								title={category.name}
							/>
							<figcaption>{category.name}</figcaption>
						</figure>
                        {checkbox()}
				</li>
			}} />
	</ul>;
}

export default withTranslation()(Categories);
