import React from "react";
import { useEffect } from "../../utils/reactUtils";
import { loadImage, saveImage, removeImage } from "../../utils/idb";

function DbImage({ imageId, src, ...imgProps }) {
	const [url, setUrl] = React.useState(src);

	useEffect((ifMounted, onCleanup) =>
        imageId && !src &&
        loadImage(imageId)
            .then(ifMounted(image => {
                const imageUrl = URL.createObjectURL(image.data);
                onCleanup(() => URL.revokeObjectURL(imageUrl));
                setUrl(imageUrl);
            }))
            .catch(error =>
                console.error("Erro ao carregar imagem", error))
	, [ imageId, src ]);
    // eslint-disable-next-line jsx-a11y/alt-text
	return <img {...imgProps} src={url} />;
  }


function InputImage({
    record, fieldName, fieldValue, placeholder, title, required, accept,
    maxItems, maxSize
}) {

	const hiddenFileInput = React.useRef(null);

	function chooseFile(){
		hiddenFileInput.current.click();
	}

	function getAccept() {
		if (!accept) {
			return "image/*";
		}
		if (accept instanceof Array) {
			return accept.map(o => {
				switch (o) {
					case 'svg': return "image/" + o + '+xml';
					case 'jpg':
					case 'jpeg': return "image/jpeg";
					default: return "image/" + o;
				}
			}).join(', ');
		}
	}
    const imageList = fieldValue ? Array(fieldValue).flat() : [];
    const limit = maxItems - imageList.length;

    function handleChangeFile(event) {

        const input = event.target;

        if (input.files.length === 0) {
            return;
        }

        const allowed_mime_types = [
            'image/jpeg', 'image/jpg', 'image/gif',
            'image/svg+xml', 'image/png'
        ];
        maxSize = maxSize || 5;
        const files = Array.from(input.files);

        const error =

            ((files.length > limit) &&
                'Escolha no máximo ' + limit + (limit > 1 ? ' imagens.' : ' imagem')) ||

            files.find(file =>
                ((allowed_mime_types.indexOf(file.type) === -1) &&
                    ('Escolha uma imagem no formato JPEG ou PNG. \nO arquivo ' + file.name + ' estava no formato ' + file.type)) ||
                ((file.size > maxSize*1024*1024) &&
                    ('O arquivo ' + file.name + ' é muito grande. \nEscolha um arquivo de até '+maxSize+'MB.'))
            );
        if (error) {
            alert(error); return;
        }

        Promise
            .all(files.map(saveImage))
            .then(resultList =>
                resultList.map(({ id, name }) => ({ id, name })))
            .then(newList =>
                record.setFieldValue(
                    fieldName, [...imageList, ...newList]));
    }

    function removeItem(index) {
        const imageId = imageList[index].id;
        if (imageId) removeImage(imageId);
        record.setFieldValue(fieldName, [
            ...imageList.slice(0, index),
            ...imageList.slice(index + 1)
        ]);
    }

    return <React.Fragment>
        <ul>
            {
                imageList.map((image, index) => <li key={index}>
                    <figure className="image-view">
                        <button onClick={() => removeItem(index)} type="button" className="remove" title="Remover esta imagem">x</button>
                        <DbImage imageId={ image.id } src={ image.src } alt={ image.name }/>
                    </figure>
                </li>)
            }
            { (imageList.length < maxItems) && (
                <li className="choice">
                    {imageList.length ? '' : <span>Nenhuma imagem foi escolhida.</span> }
                    <button type="button" onClick={chooseFile}>
                        { maxItems === 1 ? 'Escolha uma imagem' : `Adicione até ${ limit } image` + (limit > 1 ? 'ns' : 'm') }
                    </button>
                </li>
            )}
        </ul>
        <input name={ fieldName }
            id={ fieldName }
            type='file'
            required={ required && !imageList.length }
            onChange={ handleChangeFile }
            placeholder={ placeholder }
            title={ title }
            multiple={ limit > 1 }
            accept={ getAccept() }
            ref={ hiddenFileInput }
        />
    </React.Fragment>;
}

export default InputImage;

