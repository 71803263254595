import React from "react";

const TabContext = React.createContext();

function Tab({ id, children }) {
    const context = React.useContext(TabContext);
    if (!context) {
        throw new Error("Tab component can be used only as an immediate child of TabControl component");
    }
    return (context.id === id) && <TabContext.Provider value={null}>
        {children}
    </TabContext.Provider>;
}

function TabControl({
    activeTabId, setActiveTabId, children
}) {

    const childrenArray = Array(children).flat();
    if (childrenArray.some(child => child.type !== Tab)) {
        throw new Error("TabControl elements can only have Tab elements as its children");
    }

    return (
        <div className="tabs">
            <div className='selector'>
                {childrenArray.map((child, index) =>
                    <button
                        key={index}
                        type='button'
                        title={child.props.hint || ''}
                        className={child.props.id === activeTabId ? 'selected' : ''}
                        onClick={() => setActiveTabId(child.props.id)}>
                        {child.props.label}
                    </button>
                )}
            </div>
            <ul className='content'>
                <TabContext.Provider value={{ id: activeTabId }}>
                    {children}
                </TabContext.Provider>
            </ul>
        </div>
    );
}

export { TabControl, Tab };
