import React from 'react';

class Invite extends React.Component {
    render() {
        const ad = null;
        return  (
			<main className="invite">
                <h1>Convidar amigos</h1>
				{!ad && (
					<div className='empty'>
                        Você ainda não possui anúncios. Os convites serão liberados quando você criar seu primeiro anúncio.
                    </div>
				)}
			</main>
		);
    }
}

export default Invite;